'use client'

import React from 'react';
import { useMediaQuery } from 'react-responsive';

import FadeInAnimation from '@/components/Animations/FadeIn/index.client';
import Image from 'next/image';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Text from '@/common/Text/index.server';
import { resolveImageSrc } from '@/helpers/cms/images.hl';

import 'swiper/css';
import 'swiper/css/navigation';

import styles from './index.module.sass';

const Swiper = ({ events }: { events: any[] }) => {
    const isLaptopL = useMediaQuery({
        query: '(max-width: 1439px)'
    });

    const isTablet = useMediaQuery({
        query: '(max-width: 767px)'
    });

    const getSize = (isActive: boolean) => {
        return isTablet ? 248 : isLaptopL ? 320 : isActive ? 560 : 400;
    }

    return (
        <SwiperComponent
            slidesPerView={1}
            spaceBetween={0}
            lazyPreloadPrevNext={2}
            className="wi"
            breakpoints={{
                1250: {
                    slidesPerView: 2,
                    spaceBetween: 0
                }
            }}
            loop
            centeredSlides
            navigation
            modules={[Navigation]}
        >
            {
                events.map((item, index) => {
                    const { preview, mainExternalImage } = item;
                    return (
                        <SwiperSlide key={index}>
                            {({ isActive }: any) => (
                                <div>
                                    <FadeInAnimation margin="200px" delay={0.1}>
                                        <div className="fl fl--align-c fl--justify-c relative--core">
                                            <Image
                                                className={`${styles.swiper__image} ${isActive ? styles['swiper__image--active'] : ''}`}
                                                src={resolveImageSrc({ internal: preview, external: mainExternalImage })}
                                                alt="member"
                                                loading="lazy"
                                                width={getSize(isActive)}
                                                height={getSize(isActive)}
                                                sizes={`
                                                        (max-width: 767px) 248px,
                                                        (max-width: 1439px) 320px,
                                                        ${isActive ? 560 : 400}px
                                                    `}
                                            />
                                        </div>
                                    </FadeInAnimation>
                                    <FadeInAnimation margin="200px" delay={0.2}>
                                        <div className={`${styles['swiper__content-wrapper']} fl fl--justify-c`}>
                                            <div className={`${styles.swiper__content} fl fl--align-c fl--dir-col fl--justify-c`}>
                                                <Text size="big" className={`${styles.swiper__title} ${isActive ? styles['swiper__title--active'] : ''} font--family-copperplate font--uppercase margin--b-18`}>
                                                    {item?.contents?.title}
                                                </Text>
                                                <div
                                                    className={`${styles.swiper__text} ${isActive ? styles['swiper__text--active'] : ''} static turn--center`}
                                                    dangerouslySetInnerHTML={{ __html: item?.contents?.text }}
                                                />
                                            </div>
                                        </div>
                                    </FadeInAnimation>
                                </div>
                            )}
                        </SwiperSlide>
                    );
                })
            }
        </SwiperComponent>
    )
}

export default Swiper;
