"use client"

import { motion } from "framer-motion";
import curvedStyles from './index.module.sass';

const CurvedLine = (props: { 
    variants: any, 
    move: any, 
    linePath: string,
    moveCustomRules: any,
    svgCustom: any,
    color: string,
    label: string
}) => {
    const { variants, move, moveCustomRules, linePath, color, label, svgCustom } = props;
    return (
        <motion.svg
            {...svgCustom}
            fill="none"
            viewport={{ once: true }}
            initial="hidden"
            whileInView="visible"
            className={curvedStyles[`economy-curved__line-${label}`]}
        >
            <motion.path custom={2} variants={variants} d={linePath} stroke={color}></motion.path>
            <motion.circle 
                cx="10" 
                cy="4" 
                r="4"
                fill={color}
                variants={move}
                custom={moveCustomRules}
            />
        </motion.svg>
    )
}

export default CurvedLine;