const LIMIT = 9;
const LIMIT_SEVEN = 7;
const LIMIT_FIVE = 5;
const LIMIT_SIX = 6;
const LIMIT_FOUR = 4;
const LIMIT_THREE = 3;
const LIMIT_NINE = 9;
const LIMIT_TEN = 10;
const INITIAL_OFFSET = 0;

export {
    LIMIT,
    LIMIT_SIX,
    LIMIT_FIVE,
    LIMIT_SEVEN,
    LIMIT_NINE,
    LIMIT_TEN,
    INITIAL_OFFSET,
    LIMIT_FOUR,
    LIMIT_THREE
}