'use client';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useMediaQuery } from 'react-responsive';
import { IDonutChartData } from '../../types';

interface IChart {
    donutChartData: IDonutChartData[];
    className?: string;
}

const GRADIENTS = [
    {
        id: "gradient-0",
        cx: "49.81%",
        cy: "54.6%",
        r: "103.8%",
        stops: [
            { offset: "0%", stopColor: "#360A02" },
            { offset: "100%", stopColor: "rgba(54, 10, 2, 0.4)" },
        ],
    },
    {
        id: "gradient-1",
        cx: "26.21%",
        cy: "12.49%",
        r: "87.48%",
        stops: [
            { offset: "0%", stopColor: "#6C1304" },
            { offset: "100%", stopColor: "rgba(108, 19, 4, 0.2)" },
        ],
    },
    {
        id: "gradient-2",
        cx: "50%",
        cy: "50%",
        r: "133.6%",
        stops: [
            { offset: "0%", stopColor: "#6C1304" },
            { offset: "100%", stopColor: "rgba(108, 19, 4, 0.2)" },
        ],
    },
    {
        id: "gradient-3",
        cx: "50%",
        cy: "50%",
        r: "126%",
        stops: [
            { offset: "0%", stopColor: "#933431" },
            { offset: "100%", stopColor: "rgba(147, 52, 49, 0.2)" },
        ],
    },
    {
        id: "gradient-4",
        cx: "50%",
        cy: "50%",
        r: "166.15%",
        stops: [
            { offset: "0%", stopColor: "#A64845" },
            { offset: "100%", stopColor: "rgba(166, 72, 69, 0.2)" },
        ],
    },
    {
        id: "gradient-5",
        cx: "50%",
        cy: "50%",
        r: "151.24%",
        stops: [
            { offset: "0%", stopColor: "#B85C59" },
            { offset: "100%", stopColor: "rgba(184, 92, 89, 0.2)" },
        ],
    },
    {
        id: "gradient-6",
        cx: "49.98%",
        cy: "37.33%",
        r: "87.17%",
        stops: [
            { offset: "0%", stopColor: "#200601" },
            { offset: "100%", stopColor: "rgba(32, 6, 1, 0.2)" },
        ],
    },
];

interface CustomLabelProps {
    name: string;
    value: number;
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
}

const renderCustomLabel = ({
    name,
    value,
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
}: CustomLabelProps) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <>
            <text
                x={x}
                y={y - 8}
                fill="url(#text-shadow-gradient)"
                filter="url(#blur)"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
                className="link--backlight-cur-element font--400 text--small font--family-copperplate"
            >
                {value}%
            </text>

            <text
                x={x}
                y={y + 8}
                fill="#A6A6A6"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
                className="font--400 text--tiny font--family-ubuntu"
            >
                {name}
            </text>
            <text
                x={x}
                y={y - 8}
                fill="#CDCDCD"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
                className="font--400 text--small font--family-copperplate"
            >
                {value}%
            </text>
        </>
    );
};

const DonutChart = (props: IChart) => {
    const {
        donutChartData = [],
        className = ''
    } = props;

    const isLowerMode = useMediaQuery({
        query: '(max-width: 767px)'
    });

    const isMobile = useMediaQuery({
        query: '(max-width: 479px)'
    });

    return (
        <div className={className}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart
                    margin={{
                        top: isMobile ? 0 : 20,
                        right: isMobile ? 0 : 44,
                        bottom: isMobile ? 0 : 20,
                        left: isMobile ? 0 : 44
                    }}
                >
                    <defs>
                        {GRADIENTS.map((gradient) => (
                            <radialGradient
                                id={gradient.id}
                                key={gradient.id}
                                cx={gradient.cx}
                                cy={gradient.cy}
                                r={gradient.r}
                            >
                                {gradient.stops.map((stop, index) => (
                                    <stop key={index} offset={stop.offset} stopColor={stop.stopColor} />
                                ))}
                            </radialGradient>
                        ))}
                        <linearGradient id="text-shadow-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="17.9%" stopColor="#FFC6C6" stopOpacity={1} />
                            <stop offset="42.85%" stopColor="#D87B7B" stopOpacity={1} />
                            <stop offset="103.93%" stopColor="#FF1D1D" stopOpacity={1} />
                        </linearGradient>
                        <filter id="blur" x="-40%" y="-40%" width="180%" height="180%">
                            <feGaussianBlur stdDeviation="6" />
                        </filter>
                    </defs>

                    <Pie
                        data={donutChartData}
                        cx={isMobile ? "50%" : isLowerMode ? "47%" : "50%"}
                        cy="50%"
                        outerRadius={isMobile ? "100%" : isLowerMode ? "74%" : "80%"}
                        innerRadius={isMobile ? "40%" : isLowerMode ? "25%" : "30%"}
                        fill="#6C1304"
                        dataKey="value"
                        label={isMobile ? undefined : renderCustomLabel}
                        labelLine={{ stroke: '#A6A6A6', strokeWidth: 1 }}
                        startAngle={120}
                        endAngle={480}
                    >
                        {donutChartData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={`url(#gradient-${index})`}
                                stroke="#6C1304"
                                strokeWidth={1}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DonutChart;
