// Types
import {
  ITokenScannerTransactionsPayload,
  ITokenScannerTransactionsResponse,
  ITokenScannerTransactionStatisticResponse,
  ITokenStatisticResponse,
} from "@/types/token.types";

const getScannerTransactions = async (opts: ITokenScannerTransactionsPayload): Promise<ITokenScannerTransactionsResponse | null> => {
  try {

    const { search, limit, offset } = opts;
    const searchParams = new URLSearchParams({
      limit,
      offset,
      ...(search ? { search } : {})
    });

    const response = await fetch(
      `/api/transactions?${searchParams.toString()}`,
      {
        next: { revalidate: 1 },
      }
    );

    return response.json() || null;
  } catch (e) {
    return null;
  }
};

const getTransactionStatistics = async (): Promise<(Partial<ITokenScannerTransactionStatisticResponse> & Partial<ITokenStatisticResponse>) | null> => {
  try {
    const response = await fetch(
      '/api/transactions/statistics',
      {
        next: { revalidate: 1 },
      }
    );

    return response.json() || null;
  } catch (e) {
    return null;
  }
};

export {
  getTransactionStatistics,
  getScannerTransactions
};
