'use client';
import { useRouter } from 'next/navigation';

// Components
import Text from '@/common/Text/index.server';

// Types
import { ITextContent } from '@/types/page.types';

// Constants
import { NET_TYPES } from '@/constants/net.const';

// Styles
import styles from './index.module.sass';

interface IProps {
    type: string;
    translations: ITextContent;
    className?: string;
}

const NetSwitch = (props: IProps) => {
    const {
        translations: {
            metaData: {
                json: {
                    mainNet = '',
                    testNet = ''
                } = {}
            } = {}
        },
        type,
        className
    } = props;

    const router = useRouter();

    const handleClick = () => {
        const newNetType = type === NET_TYPES.TEST_NET ? NET_TYPES.MAIN_NET : NET_TYPES.TEST_NET;
        sessionStorage.setItem('account-net-type', newNetType)
        router.push(`/account/${newNetType}`)
    }

    return (
        <div className={`${styles['net-switch']} ${className ? className : 'fl fl--align-c'}`}>
            <Text
                fontFamily="copperplate"
                weight="400"
                size="l-minimum"
                { ...(type === NET_TYPES.TEST_NET ? { color: 'middle-gray' } : {})}
                transform="uppercase"
                className={`font--no-wrap ${type === NET_TYPES.MAIN_NET ? 'text-decoration--highlighted-background' : ''}`}
            >
                {mainNet}
            </Text>
            <div
                role="checkbox"
                aria-checked="true"
                tabIndex={-1}
                onClick={handleClick}
                className={`${styles['net-switch__body']} ${styles[`net-switch__body--${type}`]} relative--core cursor--custom-pointer`}
            >
                <div className={`${styles['net-switch__item']} ${styles[`net-switch__item--${type}`]}`} />
            </div>
            <Text
                fontFamily="copperplate"
                weight="400"
                size="l-minimum"
                { ...(type === NET_TYPES.MAIN_NET ? { color: 'middle-gray' } : {})}
                transform="uppercase"
                className={`font--no-wrap ${type === NET_TYPES.TEST_NET ? 'text-decoration--highlighted-background' : ''}`}
            >
                {testNet}
            </Text>
        </div>
    );
};

export default NetSwitch;
