'use client';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

// Components
import Text from '@/common/Text/index.server';

// Types
import { IMenuSingleLanguage } from '@/types/menu.types';

// Styles
import styles from './index.module.sass';

const ClientMenu = (props: { menu: IMenuSingleLanguage[] }) => {
    const { menu = [] } = props;
    const pathname = usePathname();

    return (
        <div className={`${styles.menu} relative--core`}>
            {
                menu?.map((el) => {
                    const { name, label, uri } = el;
                    const active = pathname === uri ? styles['menu__item--active'] : '';
                    return (
                        <Link key={label} href={uri} className={`${styles.menu__item} ${active} fl fl--align-c fl--justify-st cursor--custom-pointer`}>
                            <Text
                                size="x-default"
                                transform="uppercase"
                                className={`${styles['menu__item-text']} relative--core z--1 font--family-copperplate`}
                                color="dark-gray"
                            >
                                {(name as string)}
                            </Text>
                        </Link>
                    )
                })
            }
        </div>
    );
};

export default ClientMenu;
