'use client';
import React, { useEffect, useRef, useState } from 'react';

// Components
import Text from '@/common/Text/index.server';
import FadeIn from '@/components/Animations/FadeIn/index.client';
import Chart from '../Chart/index.client';
import Controls from '../Controls/index.client';
import VestingChart from '../VestingChart/index.client';
import DonutChart from '../DonutChart/index.client';
import Slider from 'react-slick';

// Types
import { ITextContent } from '@/types/page.types';
import { IChartsData } from '../../types';

// Styles
import styles from '../../index.module.sass';

interface IProps {
  chartsData: IChartsData;
  translations: ITextContent;
  chartsTitles: string[];
}

const ChartSlider = (props: IProps) => {
  const {
    chartsData: {
      vestingChartData = [],
      donutChartData = []
    },
    translations,
    translations: {
      metaData: {
        json: {
          closingPrice = '',
          volume = '',
          gameTreasuryUnlock = '',
          publicInvestors = '',
          seed = '',
          team = ''
        } = {}
      } = {}
    } = {},
    chartsTitles
  } = props;

  const [numberOfGames, setNumberOfGames] = useState<number>(2);
  const [numberOfGamers, setNumberOfGamers] = useState<number>(100000);
  const [ecosystemTransactions, setEcosystemTransactions] = useState<number>(150);

  const sliderRef = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    beforeChange: (_: number, newIndex: number) => setCurrentSlide(newIndex),
  };

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
    sliderRef.current?.slickGoTo(index);
  };

  return (
    <div
      className={`${styles.scene__slider} width--100`}
    >
      <div className={styles['scene__slider-controls']}>
        {
          chartsTitles.length ? chartsTitles?.map((title, i) => (
            <div
              key={title}
              onClick={() => goToSlide(i)}
              className={`${styles['scene__slider-control']} link--secondary ${currentSlide === i ? `link--active ${styles['scene__slider-control--active']}` : ""} text--x-default-subtitle font--400 font--uppercase font--family-copperplate cursor--custom-pointer`}
            >
              {title}
            </div>
          )) : null
        }
      </div>

      <Slider ref={sliderRef} {...settings}>
        <div>
          <FadeIn delay={0.7} className="width--100">
            <Controls
              translations={translations}
              numberOfGames={numberOfGames}
              setNumberOfGames={setNumberOfGames}
              numberOfGamers={numberOfGamers}
              setNumberOfGamers={setNumberOfGamers}
              ecosystemTransactions={ecosystemTransactions}
              setEcosystemTransactions={setEcosystemTransactions}
            />
          </FadeIn>
          <div className={styles['scene__legend-wrapper']}>
            <div className={styles.scene__legend}>
              <Text size="tiny" fontFamily="ubuntu" weight="400">{volume}</Text>
            </div>
            <div className={styles.scene__legend}>
              <Text size="tiny" fontFamily="ubuntu" weight="400">{closingPrice}</Text>
            </div>
          </div>
          <Chart
            translations={translations}
            className={styles.scene__chart}
            numberOfGames={numberOfGames}
            numberOfGamers={numberOfGamers}
            ecosystemTransactions={ecosystemTransactions}
          />
        </div>
        <div>
          <div className={`${styles['scene__legend-wrapper']} ${styles['scene__vesting-legend-wrapper']}`}>
            <div className={styles.scene__legend}>
              <Text size="tiny" fontFamily="ubuntu" weight="400">{gameTreasuryUnlock}</Text>
            </div>
            <div className={styles.scene__legend}>
              <Text size="tiny" fontFamily="ubuntu" weight="400">{publicInvestors}</Text>
            </div>
            <div className={styles.scene__legend}>
              <Text size="tiny" fontFamily="ubuntu" weight="400">{seed}</Text>
            </div>
            <div className={styles.scene__legend}>
              <Text size="tiny" fontFamily="ubuntu" weight="400">{team}</Text>
            </div>
          </div>
          <VestingChart
            vestingChartData={vestingChartData}
            translations={translations}
            className={styles['scene__vesting-chart']}
          />
        </div>
        <div>
          <DonutChart
            donutChartData={donutChartData}
            className={`${styles['scene__donut-chart']} fl fl--dir-col fl--align-c`}
          />
          <div className={styles['scene__donut-chart-legend']}>
            {donutChartData.map((item) => {
              const { name, value, modifier } = item;

              return (
                <div key={`donut chart mobile legend - ${name}`} className={`${styles['scene__donut-chart-legend-item']} ${styles[`scene__donut-chart-legend-item--${modifier}`]}`}>
                  <Text size="small" fontFamily="copperplate" weight="400">
                    {value}%
                  </Text>
                  <Text size="minimum" fontFamily="ubuntu" weight="400" color="middle-gray">
                    {name}
                  </Text>
                </div>
              )
            })}
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default ChartSlider;
