import(/* webpackMode: "eager" */ "/poliada/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/poliada/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/poliada/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/poliada/public/img/open-graph.png");
import(/* webpackMode: "eager" */ "/poliada/public/img/poliada-logo.svg");
import(/* webpackMode: "eager" */ "/poliada/src/common/Icon/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/common/Modal/Server/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/common/Pagination/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/About/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Accordion/AccordionItem/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Animations/FadeIn/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Animations/Slides/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Blog/BlogList/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Blog/BlogModal/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/BackgroundLayout/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/ClientBalances/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/ClientHeader/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/ConnectWalletModal/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Documents/DocumentsTextBlock/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/DynamicAccountLink/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Economy/Animations/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Factions/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Factions/Slider/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Footer/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Documents/Information/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Header/components/AuthWrapper/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Header/header.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Economy/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Documents/DocumentsMenu/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/ImagineAGame/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/Investments/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/Process/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/Scene/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/Tokenomics/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/WhatIsPoliada/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Language/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Logo/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/WhyPoliada/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/Economy/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/LottieAnimation/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Menu/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/RoadMap/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/CTA/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/FAQ/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Accordion/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/BackgroundLayout/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/RoadMap/RoadLine/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/RoadMap/RoadText/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Scene/Content/index.server.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Scene/Layer/index.server.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/SideBar/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/SidebarMenu/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Swiper/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/TestnetPlug/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Token/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/Team/index.module.sass");
import(/* webpackMode: "eager" */ "/poliada/src/components/TokenScanner/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Underground/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/media/pa_border.svg")