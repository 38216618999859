'use client'
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Image from "next/image";
import Link from "next/link";
import Countdown from "react-countdown";

// Components
import FadeInAnimation from '@/components/Animations/FadeIn/index.client';
import Text from "@/common/Text/index.server";

// Types
import { ITextContent } from "@/types/text.types";

// Helpers
import getFullPath from '@/helpers/getFullPath.hl';

// Styles
import sceneStyles from '../index.module.sass';

// Images
import circleImage from '../hero-bg.webp';

const SceneContent = (props: { presale: ITextContent, text: ITextContent, translation: ITextContent, lang: string }) => {
  const {
    translation: {
      metaData: {
        json: {
          joinTheBattle = '',
          presaleStartsIn = '',
          days: daysTranslation = '',
          hours: hoursTranslation = '',
          minutes: minutesTranslation = '',
          seconds: secondsTranslation = ''
        } = {}
      } = {}
    } = {},
    presale: {
      options: {
        json: {
          isPresaleActive = false,
          presaleDate = 0
        } = {}
      } = {}
    } = {},
    lang
  } = props;
  const [videoParams, setVideoParams] = useState<{ src: string, type: string } | undefined>();
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });

  const resolveVideoParams = () => {
    const isIOS = typeof navigator !== 'undefined' ? navigator?.userAgent?.match(/iPhone|iPad|iPod/i) : null;
    if (!!isIOS) {
      return {
        src: './videos/hero-animation-ios.mov',
        type: 'video/mp4'
      }
    } else {
      return {
        src: './videos/hero-animation.webm',
        type: 'video/webm'
      }
    }
  }

  useEffect(() => {
    if (typeof navigator === 'undefined') return;
    setVideoParams(resolveVideoParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderer = ({ days, hours, minutes, seconds }: any) => {
    const formatNumber = (num: number) => String(num).padStart(2, "0");
    return (
      <div className={`${sceneStyles.scene__countdown}`}>
        <div>
          <Text
            size="q-medium"
            fontFamily="copperplate"
            transform="uppercase"
            className={sceneStyles['scene__countdown-title']}
          >
            {presaleStartsIn}
          </Text>
          <div className={`${sceneStyles['scene__countdown-block']} fl fl--gap-16`}>
            <div className="fl fl--dir-col fl--align-c">
              <Text className={sceneStyles['scene__countdown-numbers']} size="z-large">{formatNumber(days)}</Text>
              <Text className={sceneStyles['scene__countdown-block-name']} fontFamily="copperplate" color="extra-gray">{daysTranslation}</Text>
            </div>
            <Text className={sceneStyles['scene__countdown-numbers']} size="z-large">:</Text>
            <div className="fl fl--dir-col fl--align-c">
              <Text className={sceneStyles['scene__countdown-numbers']} size="z-large">{formatNumber(hours)}</Text>
              <Text className={sceneStyles['scene__countdown-block-name']} fontFamily="copperplate" color="extra-gray">{hoursTranslation}</Text>
            </div>
          </div>
        </div>
        <Text className={`${sceneStyles['scene__countdown-numbers']} ${sceneStyles['scene__countdown-separator']} margin--l-8 margin--r-8`} size="z-large">:</Text>
        <div className={`${sceneStyles['scene__countdown-block']} ${sceneStyles['scene__countdown-block--second']} fl fl--gap-16`}>
          <div className="fl fl--dir-col fl--align-c">
            <Text className={sceneStyles['scene__countdown-numbers']} size="z-large">{formatNumber(minutes)}</Text>
            <Text className={sceneStyles['scene__countdown-block-name']} fontFamily="copperplate" color="extra-gray">{minutesTranslation}</Text>
          </div>
          <Text className={`${sceneStyles['scene__countdown-numbers']} ${sceneStyles['scene__countdown-numbers--second-separator']}`} size="z-large">:</Text>
          <div className="fl fl--dir-col fl--align-c">
            <Text className={sceneStyles['scene__countdown-numbers']} size="z-large">{formatNumber(seconds)}</Text>
            <Text className={sceneStyles['scene__countdown-block-name']} fontFamily="copperplate" color="extra-gray">{secondsTranslation}</Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${sceneStyles.scene} width--100`}>

      {!isPresaleActive && presaleDate > Date.now() ? (
        <>
          <Text
            size="q-medium"
            fontFamily="copperplate"
            transform="uppercase"
            className={`${sceneStyles['scene__countdown-title']} ${sceneStyles['scene__countdown-title--mobile']}`}
          >
            {presaleStartsIn}
          </Text>
          <Countdown date={presaleDate} renderer={renderer} />
        </>
      ) : null}
      <div className={sceneStyles['scene__main-hero-wrapper']}>
        <div className={sceneStyles['scene__circle-bg']}>
          <Image
            src={circleImage}
            alt="circle background"
            className={`${sceneStyles['scene__circle-bg-image']} absolute--core`}
            sizes={`
              (max-width: ${sceneStyles.$widthMobile}px) 750px,
              (max-width: ${sceneStyles.$widthTablet}px) 766px,
              (max-width: ${sceneStyles.$widthTabletL}px) 1150px,
              (max-width: ${sceneStyles.$widthLaptop}px) 1242px,
              1400px
            `}
            priority
          />
        </div>
        {
          videoParams ? (
            <>
              <video
                className={`${sceneStyles['scene__main-hero-video']} ${sceneStyles['scene__main-hero-video--shadow']}`}
                autoPlay
                loop
                muted
                playsInline
                width="984"
                height="752"
                style={{
                  maskImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1) ${isMobile ? '40%' : '44%'}, rgba(0, 0, 0, 0) ${isMobile ? '62%' : '70%'})`,
                  WebkitMaskImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1) ${isMobile ? '40%' : '44%'}, rgba(0, 0, 0, 0) ${isMobile ? '62%' : '70%'})`,
                }}
              >
                <source src={videoParams.src} type={videoParams.type} />
              </video>
              <video
                className={sceneStyles['scene__main-hero-video']}
                autoPlay
                loop
                muted
                playsInline
                width="984"
                height="752"
                style={{
                  maskImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1) ${isMobile ? '56%' : '44%'}, rgba(0, 0, 0, 0) ${isMobile ? '72%' : '70%'})`,
                  WebkitMaskImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1) ${isMobile ? '56%' : '44%'}, rgba(0, 0, 0, 0) ${isMobile ? '72%' : '70%'})`,
                }}
              >
                <source src={videoParams.src} type={videoParams.type} />
              </video>
            </>
          ) : null
        }
        <div className={`${sceneStyles.scene__wrapper} fl fl--dir-col fl--align-c`}>
          <div className={sceneStyles.scene__logo}>
            <FadeInAnimation delay={0.5} duration={0.5} className={sceneStyles['scene__logo-image']}>
              <Image
                fill={true}
                src="https://imagedelivery.net/JMDhGpkz8aaD825rBr6bGQ/eade2bf6-7aa6-4741-d770-5bde9745a100/public"
                alt="Poliada full logo"
                loading="eager"
              />
            </FadeInAnimation>
          </div>
        </div>
        <Link href={getFullPath('', lang, { query: { oauth: true } })} className={`${sceneStyles.scene__link} link--primary fl fl--align-c fl--justify-c padding--l-40 padding--r-40 font--uppercase font--family-copperplate text--x-default cursor--custom-pointer`}>
          {joinTheBattle}
        </Link>
      </div>
    </div>
  )
}

export default SceneContent;
