'use client';
import Link from 'next/link';

// Components
import Text from '@/common/Text/index.server';
import FadeIn from '@/components/Animations/FadeIn/index.client';
import DynamicAccountLink from '@/components/DynamicAccountLink/index.client';
import ChartSlider from './components/ChartSlider/index.client';

// Types
import { ITextContent } from '@/types/text.types';

// Styles
import styles from './index.module.sass';

interface IProps {
  text: ITextContent;
  translations: ITextContent;
}

const InvestorsScene = (props: IProps) => {
  const {
    text: {
      title = '',
      text = '',
      metaData: {
        json: {
          chartsTitles = [],
          chartsData = {}
        } = {}
      } = {}
    } = {},
    translations,
    translations: {
      metaData: {
        json: {
          or = '',
          subscribeToNewsletter = '',
          becomeAnInvestor = ''
        } = {}
      } = {}
    } = {}
  } = props;

  return (
    <section className={`${styles.scene} indent--page`}>
      <div className="container fl fl--dir-col fl--align-c">
        <div className={styles.scene__content}>
          <FadeIn delay={0.1}>
            <Text
              tag="h1"
              size="y-huge"
              weight="400"
              className={`${styles.scene__title} margin--b-24 font--family-copperplate`}
            >
              {title}
            </Text>
          </FadeIn>
          <FadeIn delay={0.3}>
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className="margin--b-48 text--x-default font--300 color--extra-gray font--family-ubuntu"
            />
          </FadeIn>
          <FadeIn delay={0.5} className={styles.scene__link}>
            <DynamicAccountLink
              loggedOutParams={{
                'oauth': 'true',
                'form': 'signup',
                'kyc': 'true'
              }}
              loggedInParams={{
                'kyc': 'true'
              }}
              className="fl fl--align-c fl--justify-c radius--100 color--white link--primary font--uppercase font--family-copperplate text--x-default cursor--custom-pointer"
            >
              {becomeAnInvestor}
            </DynamicAccountLink>
            <Text
              tag="p"
              size="minimum"
              color="extra-gray"
              weight="300"
              className={`${styles['scene__subscribe-link-wrapper']} font--family-ubuntu`}
            >
              {`${or} `}
              <Link
                href="#subscribe"
                className={`${styles['scene__subscribe-link']} color--extra-gray text--minimum text-decoration--underline cursor--custom-pointer`}
              >
                {subscribeToNewsletter}
              </Link>
            </Text>
          </FadeIn>
        </div>
        <ChartSlider chartsData={chartsData} translations={translations} chartsTitles={chartsTitles} />
      </div>
    </section>
  );
};

export default InvestorsScene;
