'use client';
import { DEFAULT_LOCALE } from '@/configuration/locales';
import { useCookies } from "react-cookie";

// Components
import Logo from "@/components/Logo/index.server"
import Profile from '../Profile/index.client';
import ClientSidebar from '../ClientSidebar/index.client';
import Language from '../Language/index.client';
import { ConnectWalletModal } from "@/components/index.client";
import ConnectWalletErrorModal from "@/components/ConnectWalletErrorModal/index.client";
import ClientMenu from '../ClientMenu/index.client';
import NetSwitch from '../NetSwitch/index.client';

// Store
import { useWalletStore } from '@/store/zustand/wallet.store';
import { useUserStore } from "@/store/zustand/user.store";

// Types
import { ITextContent } from '@/types/text.types';
import { IMenuSingleLanguage } from '@/types/menu.types';

// Styles
import styles from './index.module.sass';

interface IProps {
    translations: ITextContent,
    text: ITextContent,
    lang: string,
    menu: IMenuSingleLanguage[],
    netType: string
};

const ClientHeader = (props: IProps) => {
    const {
        text,
        translations,
        translations: {
            metaData: {
                json = {}
            } = {}
        } = {},
        menu,
        netType
    } = props;
    const [{ lang = DEFAULT_LOCALE }] = useCookies();

    const {
        isConnectWalletModalActive,
        walletProviderError
    } = useWalletStore();

    const {
        user: {
            email
        }
    } = useUserStore();

    return (
        <header className={`${styles.header} ${styles[`header--${netType}`]} fl fl--justify-c width--100`}>
            {walletProviderError ? (
                <ConnectWalletErrorModal
                    translations={translations}
                />
            ) : null}
            {isConnectWalletModalActive ? (
                <ConnectWalletModal
                    text={text}
                    translation={translations}
                    lang={lang}
                />
            ) : null}
            <div className="relative--core container container--account">
                <div className={`${styles.header__inner} width--100`}>
                    <div className="fl fl--align-c">
                        <Logo
                            path={`/account/${netType}`}
                            lang={lang}
                            classNames={{
                                wrapper: styles['header__logo-wrapper']
                            }}
                        />
                        <ClientMenu menu={menu} />
                    </div>
                    <NetSwitch
                        type={netType}
                        translations={translations}
                        className={styles['header__net-switch']}
                    />
                    <div className={`${styles['header__info-wrapper']} fl fl--align-c`}>
                        <Language
                            isClientArea
                            lang={lang}
                            className={`${styles['header__language']} relative--core margin--l-34`}
                        />
                        <Profile
                            isWebSite={false}
                            email={email}
                            translations={json}
                            isClientHeader
                            lang={lang}
                        />
                        <ClientSidebar
                            menu={menu}
                            lang={lang}
                            translations={json}
                            email={email!}
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default ClientHeader;
