"use client"

// Components
import InputComponent from '@/components/Form/Input/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

interface IProps {
  translations: ITextContent;
  search: string | undefined;
  setSearch: (value: string) => void;
}

const TokenScannerSearch = (props: IProps) => {
  const {
    translations: {
      metaData: {
        json: {
          transactionSearch = '',
        } = {}
      } = {}
    } = {},
    search,
    setSearch
  } = props;

  return (
    <div className={`${styles['scanner-search']} fl fl--justify-c width--100`}>
      <InputComponent
        value={search}
        placeholder={transactionSearch}
        onChange={(_, val) => setSearch(val)}
        type="search"
        classNames={{
          wrapper: styles['scanner-search__input-wrapper']
        }}
      />
    </div>
  );
};

export default TokenScannerSearch;
