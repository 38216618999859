'use client';
import React, { useEffect } from 'react';

// Components
import CustomSlider from '../SingleControl/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

interface IControlsProps {
    numberOfGames: number;
    setNumberOfGames: (value: number) => void;
    numberOfGamers: number;
    setNumberOfGamers: (value: number) => void;
    ecosystemTransactions: number;
    setEcosystemTransactions: (value: number) => void;
    translations: ITextContent
}

const Controls = (props: IControlsProps) => {
    const {
        numberOfGames,
        setNumberOfGames,
        numberOfGamers,
        setNumberOfGamers,
        ecosystemTransactions,
        setEcosystemTransactions,
        translations: {
            metaData: {
                json: {
                    numberOfGames: numberOfGamesTranslation = '',
                    numberOfGamers: numberOfGamersTranslation = '',
                    ecoSystemTransactions: ecoSystemTransactionsTranslation = ''
                } = {}
            } = {}
        } = {}
    } = props;

    return (
        <div className={styles.controls}>
            <CustomSlider
                title={numberOfGamesTranslation}
                value={numberOfGames}
                min={1}
                max={10}
                step={1}
                changeValue={setNumberOfGames}
            />
            <CustomSlider
                title={numberOfGamersTranslation}
                value={numberOfGamers}
                min={100000}
                max={500000}
                step={10000}
                changeValue={setNumberOfGamers}
            />
            <CustomSlider
                title={ecoSystemTransactionsTranslation}
                value={ecosystemTransactions}
                min={100}
                max={200}
                step={10}
                changeValue={setEcosystemTransactions}
            />
        </div>
    );
};

export default Controls;
