"use client"

import { motion } from "framer-motion";
import verticalStyles from './index.module.sass'

const VerticalLine = (props: { 
    variants: any, 
    move: any, 
    lineCodrinates: { x1: string, x2: string, y1: string, y2: string },
    moveCustomRules: any,
    color: string,
    label: string
}) => {
    const { variants, move, moveCustomRules, lineCodrinates, color, label } = props;
    return (
        <motion.svg
            fill="none"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "200px" }}
            className={verticalStyles[`economy-vertical__line-${label}`]}
        >
            <motion.line
                {...lineCodrinates}
                stroke={color}
                variants={variants}
                custom={2}
            />
            <motion.circle 
                cx="10" 
                cy="4"
                r="4"
                fill={color}
                variants={move}
                custom={moveCustomRules}
            />
        </motion.svg>
    )
}

export default VerticalLine;