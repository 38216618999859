'use client';
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

// Components
import Icon from '@/common/Icon/index.client';
import Text from '@/common/Text/index.server';
import LoaderBlur from '@/common/LoaderBlur/index.client';

// Hooks
import useLogout from '@/hooks/useLogout';

// Helpers
import getFullPath from '@/helpers/getFullPath.hl';

// Constants
import { DEFAULT_LOCALE } from '@/configuration/locales';
import { NET_TYPES } from '@/constants/net.const';

// Store
import { useOauthStore } from '@/store/zustand/oauth.store';

// Styles
import styles from './index.module.sass';

interface IProps {
    isWebSite?: boolean;
    email?: string;
    translations: {
        [key: string]: string;
    };
    isClientHeader?: boolean;
    lang?: string;
}

const Profile = (props: IProps) => {
    const {
        translations: {
            personalArea = '',
            restorePassword = '',
            supportCentre = '',
            logOut = '',
            backToWebsite = ''
        } = {},
        isWebSite = true,
        isClientHeader = false,
        lang = DEFAULT_LOCALE
    } = props;
    const menuRef = useRef<HTMLDivElement>(null);
    const { email } = useOauthStore();
    const [netType, setNetType] = useState<string | null>(null);
    const { isLoggingOut, logoutHandler } = useLogout();

    useEffect(() => {
        if (typeof window === 'undefined') return; 
        const storageNetType = sessionStorage.getItem('account-net-type')
        setNetType(storageNetType || NET_TYPES.TEST_NET);
        const handleResize = () => {
            if (menuRef.current) {
                const menuRect = menuRef.current.getBoundingClientRect();
                const windowWidth = window.innerWidth;

                const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

                const buffer = 10;

                const currentTranslateX = parseFloat(
                    getComputedStyle(menuRef.current).getPropertyValue('--translateX') || '0'
                );

                const menuRightWithTranslate = menuRect.right - currentTranslateX;
                const windowLimit = windowWidth - scrollbarWidth - buffer;

                if (menuRightWithTranslate > windowLimit) {
                    const offset = menuRightWithTranslate - windowLimit;
                    menuRef.current.style.setProperty('--translateX', `${-offset}px`);
                } else {
                    menuRef.current.style.setProperty('--translateX', '0px');
                }
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window]);

    return (
        <div className={`${styles.profile} ${isWebSite ? styles['profile--main'] : ''}`}>
            <Icon
                size={18}
                icon="flat/menu-down-arrow"
                classNames={{ wrapper: `${styles['profile__down-arrow']} margin--r-8` }}
            />
            <div className={styles.profile__user}>
                <Image
                    src="/img/user.svg"
                    width={24}
                    height={24}
                    alt="user"
                />
            </div>
            <div
                ref={menuRef}
                className={`${styles.profile__menu} ${isClientHeader ? styles['profile__menu--client'] : ''}`}
            >
                <LoaderBlur fetch={isLoggingOut} />
                <Text size="small" color="middle-gray">
                    {email}
                </Text>
                {
                    isClientHeader ? (
                        <Link href={getFullPath('', lang)} className={`${styles['profile__menu-item']} width--fit-content link--secondary fl cursor--custom-pointer`}>
                            <Icon
                                size={16}
                                icon="flat/global"
                                classNames={{ wrapper: 'margin--r-8' }}
                                color="white"
                            />
                            {backToWebsite}
                        </Link>
                    ) : (
                        <Link href={`/account/${netType || NET_TYPES.TEST_NET}`} className={`${styles['profile__menu-item']} width--fit-content link--secondary fl cursor--custom-pointer`}>
                            <Icon
                                size={16}
                                icon="flat/personal-area"
                                classNames={{ wrapper: 'margin--r-8' }}
                                color="white"
                            />
                            {personalArea}
                        </Link>
                    )
                }

                <Link href={{ query: { oauth: true, form: 'restore-password-by-old' } }} className={`${styles['profile__menu-item']} width--fit-content link--secondary fl fl--align-c cursor--custom-pointer`}>
                    <div className={`${styles['profile__restore-icon']} margin--r-8 margin--t-2`} />
                    {restorePassword}
                </Link>
                {/* <Link href="#" className={`${styles['profile__menu-item']} fl cursor--custom-pointer`}>
                    <Icon
                        size={16}
                        icon="flat/support"
                        classNames={{ wrapper: 'margin--r-8' }}
                        color="white"
                    />
                    {supportCentre}
                </Link> */}
                <span onClick={logoutHandler} className={`${styles['profile__menu-item']} width--fit-content link--secondary fl font--300 text--small cursor--custom-pointer`}>
                    <Icon
                        size={16}
                        icon="flat/logout"
                        classNames={{ wrapper: 'margin--r-8' }}
                        color="white"
                    />
                    {logOut}
                </span>
            </div>
        </div>
    );
};

export default Profile;
