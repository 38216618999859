'use client';
import React from 'react';

// Types
import { IPageContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

interface IDocumentsTextBlockProps {
  page: IPageContent;
  domain: string;
}

const DocumentsTextBlock = ({ page, domain }: IDocumentsTextBlockProps) => {
  const { text = '' } = page;

  return (
    <div className={`${styles['document-text']} flex--1 margin--r-auto`}>
      <div className={`${styles['document-text__text']} static color--white`} dangerouslySetInnerHTML={{ __html: text?.replaceAll('{DOMAIN}', domain) }} />
    </div>
  )
}

export default DocumentsTextBlock;
