"use client"

import { motion } from "framer-motion";
import animationStyles from "../index.module.sass";

import Image from "next/image";
import Text from '@/common/Text/index.server';
import Blur from "@/components/Animations/Blur/index.client";

const Rectangles = (props: { variants: any, image: string, title: string, idKey: string, gradientPosition: { x1: string, x2: string, y1: string, y2: string } }) => {
    const { 
        variants,
        image,
        title,
        gradientPosition,
        idKey
    } = props;

    return (
        <div>
            <motion.svg
                width="309"
                height="308"
                viewport={{ once: true }}
                viewBox="0 0 309 308"
                fill="none"
                initial="hidden"
                whileInView="visible"
                className={animationStyles['economy-rectangles__block']}
            >
                <defs>
                    <linearGradient 
                        id={`paint0_linear_417_1848__${idKey}`}
                        x1={gradientPosition?.x1}
                        y1={gradientPosition?.y1}
                        x2={gradientPosition?.x2}
                        y2={gradientPosition?.y2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#252122" stopOpacity="1"/>
                        <stop offset="0.380131" stopColor="#323031" stopOpacity="1"/>
                        <stop offset="0.590416" stopColor="#4B4545" stopOpacity="1"/>
                        <stop offset="1" stopColor="#6C1304"/>
                    </linearGradient>
                </defs>
                <motion.rect
                    width="228"
                    height="228"
                    x="-6.69865"
                    y="153.927"
                    rx="19.5"
                    stroke={`url(#paint0_linear_417_1848__${idKey})`}
                    fill="none"
                    transform="rotate(-45 -6.69865 153.927)"
                    variants={variants}
                />
            </motion.svg>
            <Blur className="fl fl--align-c fl--justify-c fl--dir-col" delay={1}>
                <Image className={animationStyles['economy-rectangles__image']} src={image} alt={title}/>
                <Text size="x-default-subtitle" className={`${animationStyles['economy-rectangles__title']} font--family-copperplate font--uppercase`}>{title}</Text>
            </Blur>
        </div>
    )
}

export default Rectangles;
