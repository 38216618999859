"use client"
import { formatUnits } from 'ethers';

// Components
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';
import FadeIn from '@/components/Animations/FadeIn/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

// Constants
import { PADA_DEFAULT_PRECISION, PADA_DASHBOARD_PRECISION_UI } from '@/constants/token.const';
import { useEffect, useState } from 'react';
import { getTransactionStatistics } from '@/actions/transactions';
import { ITokenScannerTransactionStatisticResponse, ITokenStatisticResponse } from '@/types/token.types';

// Helpers
import { formatNumberValue } from '@/helpers/token.hl';

interface IProps {
  translations: ITextContent;
}

interface ICardData {
  icon: string;
  text: string;
  value: string;
  percent: number;
}

const formatPadaAmountStr = (value: number, precision?: number): string => {
  const str = formatUnits(BigInt(value), PADA_DEFAULT_PRECISION);
  return str.slice(0, str.indexOf('.') + (precision !== undefined ? precision : PADA_DASHBOARD_PRECISION_UI))
}

const TokenScannerHeader = (props: IProps) => {
  const {
    translations: {
      metaData: {
        json: {
          totalOwners = '',
          padaBalances = '',
          txnBalances = '',
          volume = '',
          billionSuffix = '',
          millionSuffix = '',
          thousandSuffix = ''
        } = {}
      } = {}
    } = {}
  } = props;

  const [statistics, setStatistics] = useState<(Partial<ITokenScannerTransactionStatisticResponse> & Partial<ITokenStatisticResponse>) | null>(null);

  const getStatistics = async () => {
    const statistics = await getTransactionStatistics();
    setStatistics(statistics);
  }

  useEffect(() => {
    getStatistics();
  }, []);

  const cards: ICardData[] = [
    { icon: 'total-owners', text: totalOwners, value: String(statistics?.walletsCount?.value || 0), percent: statistics?.walletsCount?.percentChange || 0 },
    { icon: 'balances', text: padaBalances, value: formatPadaAmountStr(statistics?.balance?.value || 0, 0), percent: statistics?.balance?.percentChange || 0 },
    { icon: 'txn-count', text: txnBalances, value: String(statistics?.transactionCount?.value || 0), percent: statistics?.transactionCount?.percentChange || 0 },
    { icon: 'volume', text: volume, value: formatPadaAmountStr(statistics?.volume?.value || 0, 0), percent: statistics?.volume?.percentChange || 0 }
  ];

  return (
    <div className={`${styles['scanner-header']} width--100 margin--b-24`}>
      {cards?.map((card, i) => {
        const { icon, text, value, percent } = card;

        return (
          <FadeIn key={text} delay={0.1 * i} className={`${styles['scanner-header__card']} fl fl--align-c fl--gap-16 padding--16 radius--16`}>
            <Icon icon={`colorful/scanner-${icon}`} size={48} />
            <div className="fl--1">
              <div className="fl fl--justify-b fl--align-c margin--b-4">
                <Text tag="h4" size="small" color="extra-gray" className="font--family-ubuntu">
                  {text}
                </Text>
                <Text tag="h4" size="small" color="extra-gray" className="font--family-ubuntu">
                  24h
                </Text>
              </div>
              <div className="fl fl--justify-b fl--align-end">
                <Text tag="h4" size="x-default-subtitle" className="font--family-copperplate">
                  {formatNumberValue({
                    value: +value,
                    precision: 2,
                    translations: {
                      billionSuffix,
                      millionSuffix,
                      thousandSuffix
                    }
                  })}
                </Text>
                <div className={`${percent < 0 ? styles['scanner-header__negative-percent'] : ''} fl fl--align-c fl--gap-2`}>
                  <Icon
                    icon="flat/arrow-long"
                    size={14}
                    color="color--white"
                    classNames={{
                      wrapper: styles['scanner-header__arrow-wrapper'],
                      icon: styles['scanner-header__arrow-icon']
                    }} />
                  <Text tag="h4" size="small" className={`${styles['scanner-header__percent']} font--family-ubuntu`}>
                    {Math.abs(percent)}%
                  </Text>
                </div>
              </div>
            </div>
          </FadeIn>
        )
      })}
    </div>
  );
};

export default TokenScannerHeader;
