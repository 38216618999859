"use client";
import { LazyMotion, domAnimation, m } from "framer-motion";

const Blur = (props: {
    children: React.ReactNode,
    delay?: number,
    margin?: string,
    className?: string,
    duration?: number,
    controls?: any,
    reference?: {
        onRef: (el: any) => void,
    }
}): React.ReactNode => {
    const {
        children,
        delay,
        margin,
        className,
        duration,
        controls,
        reference: {
            onRef = () => { },
        } = {}
    } = props;

    return (
        <LazyMotion features={domAnimation}>
            <m.div
                ref={(el: any) => onRef(el)}
                className={className || ''}
                viewport={{ once: true, margin: 0 || margin }}
                initial={{ opacity: 0, filter: 'blur(50px)' }}
                animate={controls}
                whileInView={{ opacity: 1, filter: 'blur(0)' }}
                transition={{
                    duration: duration || .5, delay: delay || 0,
                    filter: {
                        duration: duration || 1
                    }
                }}
            >
                {
                    children
                }
            </m.div>
        </LazyMotion>
    )
}

export default Blur;