"use client"

// Components
import TokenScannerHeader from './TokenScannerHeader/index.client';
import TokenScannerTable from './TokenScannerTable/index.client';
import TokenScannerSearch from './TokenScannerSearch/index.client';
import PaginationComponent from '@/common/Pagination/index.client';
import FadeIn from '@/components/Animations/FadeIn/index.client';
import Text from '@/common/Text/index.server';
import TokenScannerPlug from './TokenScannerPlug/index.client';
import LoaderBlur from '@/common/LoaderBlur/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Constants
import { LIMIT_NINE } from '@/configuration/rules';

// Rest
import { getTokenScannerTransactions } from '@/rest/internal/token-scaner';
import { useEffect, useState } from 'react';
import { ITokenScannerTransaction } from '@/types/token.types';

// Actions
import { getScannerTransactions } from '@/actions/transactions';

interface IProps {
  title: string;
  translations: ITextContent;
  page: number;
  search?: string;
}

const TokenScanner = (props: IProps) => {
  const {
    title = '',
    translations,
    page = 1,
    search: searchRaw
  } = props;

  const [total, setTotal] = useState<number>(0);
  const [transactions, setTransactions] = useState<ITokenScannerTransaction[]>([]);
  const [search, setSearch] = useState<string | undefined>(searchRaw);
  const [isLoading, setIsLoading] = useState(false);

  const getTransactions = async () => {
    try {
      setIsLoading(true);
      const offset = String(((page || 1) - 1) * LIMIT_NINE);
      const limit = String(LIMIT_NINE);

      const { total = 0, transactions = [] } = await getScannerTransactions({
        limit,
        offset,
        ...(search ? { search } : {})
      }) || {};

      setTotal(total);
      setTransactions(transactions);
    } catch (e) {
      setTotal(0);
      setTransactions([]);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTransactions();
  }, [page, search]);

  return (
    <div className="indent--page indent--footer">
      <div className="container">
        <FadeIn delay={0.1}>
          <Text
            size="q-huge"
            tag="h1"
            className="turn--center margin--b-24"
          >
            {title}
          </Text>
        </FadeIn>
        <TokenScannerSearch translations={translations} search={search} setSearch={setSearch} />
        {
          isLoading ? (
            <div className="relative--core height--68vh radius--16 overflow--hidden">
              <LoaderBlur fetch={isLoading} />
            </div>
          ) : transactions?.length ? (
            <>
              <TokenScannerHeader translations={translations} />
              <TokenScannerTable translations={translations} data={transactions} />
              {
                total > LIMIT_NINE ? (
                  <FadeIn delay={0.7} className="fl fl--justify-c margin--t-24">
                    <PaginationComponent
                      pageCount={(Math.ceil(total / LIMIT_NINE))}
                      current={page}
                    />
                  </FadeIn>
                ) : null
              }
            </>
          ) : (
            <FadeIn delay={0.3}>
              <TokenScannerPlug translations={translations} />
            </FadeIn>
          )
        }
      </div>
    </div>
  );
};

export default TokenScanner;
