"use client"

import { motion } from "framer-motion";
import Text from "@/common/Text/index.server";
import tagStyles from './index.module.sass';

const Tags = (props: { 
    variants: any, 
    label: string,
    title: string,
    color: string
}) => {
    const { variants, label, title, color } = props;

    return (
        <div className={`${tagStyles[`economy-tags__line-${label}-wrapper`]} fl fl--align-c fl--justify-c`}>
            <motion.svg
                fill="none"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "200px" }}
                className={tagStyles[`economy-tags__line-${label}-block`]}
            >
                <motion.rect
                    width="119"
                    height="44"
                    x="0"
                    y="0"
                    rx="8"
                    stroke={color}
                    fill="#000"
                    variants={variants}
                    custom={4}
                />
            </motion.svg>
            <motion.span 
                viewport={{ once: true, margin: "200px" }} 
                initial="hidden" 
                whileInView="visible" 
            >
                <motion.span variants={variants} custom={5}>
                    <Text className={tagStyles[`economy-tags__line-${label}-text`]} color="extra-gray">{title}</Text>
                </motion.span>
            </motion.span>
        </div>
    )
}

export default Tags;