"use client"

import dayjs from 'dayjs';
import { formatUnits } from 'ethers';

// Components
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';
import FadeIn from '@/components/Animations/FadeIn/index.client';
import Link from 'next/link';

// Types
import { ITextContent } from '@/types/page.types';
import { ITokenScannerTransaction, ITokenScannerTransactionView } from '@/types/token.types';

// Styles
import styles from './index.module.sass';

// Constants
import { CURRENCIES, PADA_DEFAULT_PRECISION, PADA_TOKEN_SCANNER_PRECISION_UI, TRANSACTION_TYPE } from '@/constants/token.const';

interface IProps {
  translations: ITextContent;
  data: ITokenScannerTransaction[]
}

const TokenScannerTable = (props: IProps) => {
  const {
    translations: {
      metaData: {
        json: {
          hash = '',
          timeUTC = '',
          transactionType = '',
          from = '',
          to = '',
          amount = '',
          fee = '',
          transactionTypes: transactionTypesTranslation = {}
        } = {}
      } = {}
    } = {},
    data = []
  } = props;

  const transactions: ITokenScannerTransactionView[] = data.map((transaction) => {
    const getShortString = (str: string): string => `${str.slice(0, 7)}...${str.slice(-5)}`;

    const formatPadaAmountStr = (value?: string): string => {
      if (!value) return 'Unrecognized';

      const str = formatUnits(value, PADA_DEFAULT_PRECISION);
      const decimalIndex = str.indexOf('.');
      if (decimalIndex === -1) return str;

      const endIndex = str.slice(decimalIndex + 1).search(/[1-9]/) + decimalIndex + 2;
      return endIndex > decimalIndex + 1 ? str.slice(0, endIndex) : str.slice(0, decimalIndex + 2);
    };


    const fromFull = transaction.from || 'Unrecognized';
    const toFull = transaction.to || 'Unrecognized';
    const from = transaction.from ? getShortString(transaction.from) : 'Unrecognized';
    const to = transaction.to ? getShortString(transaction.to) : 'Unrecognized';
    let fee = '';
    let feeCurrency = '';
    if (transaction.events[process.env.NEXT_PUBLIC_PADA_PAYMASTER_CONTRACT_ADDRESS!]?.['PostOpSuccess']?.amount) {
      fee = formatPadaAmountStr(transaction.events[process.env.NEXT_PUBLIC_PADA_PAYMASTER_CONTRACT_ADDRESS!]?.['PostOpSuccess']?.amount as string)
      feeCurrency = CURRENCIES.PADA.toUpperCase();
    } else if (transaction.events[process.env.NEXT_PUBLIC_USDT_PAYMASTER_CONTRACT_ADDRESS!]?.['PostOpSuccess']?.amount) {
      fee = formatPadaAmountStr(transaction.events[process.env.NEXT_PUBLIC_USDT_PAYMASTER_CONTRACT_ADDRESS!]?.['PostOpSuccess']?.amount as string)
      feeCurrency = CURRENCIES.USDT.toUpperCase();
    }

    if (transaction.events[process.env.NEXT_PUBLIC_PADA_CONTRACT_ADDRESS!]?.['Transfer']) {
      const amount = transaction.events[process.env.NEXT_PUBLIC_PADA_CONTRACT_ADDRESS!]?.['Transfer']?.value as string | undefined;

      let transactionType = TRANSACTION_TYPE.TRANSFER;

      if (transaction.from === process.env.NEXT_PUBLIC_LBP_CONTRACT_ADDRESS || transaction.to === process.env.NEXT_PUBLIC_LBP_CONTRACT_ADDRESS) {
        transactionType = TRANSACTION_TYPE.SWAP;
      }

      if (transaction.from === process.env.NEXT_PUBLIC_UNDERGROUND_CONTRACT_ADDRESS || transaction.to === process.env.NEXT_PUBLIC_UNDERGROUND_CONTRACT_ADDRESS) {
        transactionType = TRANSACTION_TYPE.STAKING;
      }

      if (transaction.to === process.env.NEXT_PUBLIC_EXCHANGE_CONTRACT_ADDRESS) {
        transactionType = TRANSACTION_TYPE.BURNT;
      }

      if (transaction.to === process.env.NEXT_PUBLIC_NFT_MINTING_CONTRACT_ADDRESS) {
        transactionType = TRANSACTION_TYPE.MINTING;
      }

      return {
        hash: getShortString(transaction.transactionHash),
        hashFull: transaction.transactionHash,
        time: transaction.created,
        transactionType,
        from,
        to,
        fromFull,
        toFull,
        amount: transactionType === TRANSACTION_TYPE.TRANSFER && !amount ? '-' : formatPadaAmountStr(amount),
        fee,
        feeCurrency
      }
    }

    if (transaction.events[process.env.NEXT_PUBLIC_UNDERGROUND_CONTRACT_ADDRESS!]?.['CalculateRewards']) {
      const amount = transaction.events[process.env.NEXT_PUBLIC_UNDERGROUND_CONTRACT_ADDRESS!]?.['CalculateRewards']?.reward as string | undefined;

      return {
        hash: getShortString(transaction.transactionHash),
        hashFull: transaction.transactionHash,
        time: transaction.created,
        transactionType: TRANSACTION_TYPE.REWARD,
        from: '-',
        to: '-',
        fromFull: '-',
        toFull: '-',
        amount: formatPadaAmountStr(amount),
        fee,
        feeCurrency
      }
    }

    if (transaction.events[process.env.NEXT_PUBLIC_WALLET_FACTORY_CONTRACT_ADDRESS!]?.['WalletCreated']) {
      return {
        hash: getShortString(transaction.transactionHash),
        hashFull: transaction.transactionHash,
        time: transaction.created,
        transactionType: TRANSACTION_TYPE.WALLET,
        from,
        to,
        fromFull,
        toFull,
        amount: '-',
        fee,
        feeCurrency
      }
    }

    return {
      hash: getShortString(transaction.transactionHash),
      hashFull: transaction.transactionHash,
      time: transaction.created,
      transactionType: 'Unrecognized',
      from: 'Unrecognized',
      to: 'Unrecognized',
      fromFull: 'Unrecognized',
      toFull: 'Unrecognized',
      amount: 'Unrecognized',
      fee,
      feeCurrency
    }

  });

  const titles = [
    hash, timeUTC, transactionType, amount, fee, from, to
  ];

  return (
    <FadeIn delay={0.5} className={`${styles['scanner-table']} width--100 radius--16 overflow--hidden`}>
      <div className={`${styles['scanner-table__header']} width--100`}>
        {titles?.map((title) => (
          <div key={`th - ${title}`} className={`${styles['scanner-table__th']} fl fl--justify-c padding--t-16 padding--b-16`}>
            <Text tag="h4" size="small" weight="400" className="font--family-copperplate">
              {title}
            </Text>
          </div>
        ))}
      </div>
      <div className={`${styles['scanner-table__body']} scrollbar scrollbar--inverted width--100 padding--b-16`}>
        {transactions?.map((row) => {
          const { hash, time, transactionType, from, to, amount, hashFull, fee, feeCurrency } = row;
          return (
            <div key={`tr - ${hash}`} className={`${styles['scanner-table__tr']} fl fl--align-c`}>
              <div className={`${styles['scanner-table__td']} fl fl--justify-c padding--t-16 padding--b-16`}>
                <Link
                  target="_blank"
                  href={`${process.env.NEXT_PUBLIC_ETHERSCAN_URL}/${hashFull}`}
                  className="color--extra-gray text--small font--300 hover--brown-red hover--underline font--family-ubuntu font--underline cursor--custom-pointer"
                >
                  {hash}
                </Link>
              </div>
              <div className={`${styles['scanner-table__td']} fl fl--justify-c padding--t-16 padding--b-16`}>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {dayjs(time).format('YYYY-MM-DD HH:mm')}
                </Text>
              </div>
              <div className={`${styles['scanner-table__td']} fl fl--justify-c padding--t-8 padding--b-8`}>
                <div className={`${styles['scanner-table__transaction-type']} fl fl--justify-c fl--align-c fl--gap-8 padding--t-8 padding--b-8 radius--100`}>
                  <Icon icon={`flat/transaction-type-${transactionType.toLowerCase()}`} size={16} />
                  <Text size="small" transform="capitalize" className="font--family-ubuntu">
                    {transactionTypesTranslation[transactionType.toLowerCase()]}
                  </Text>
                </div>
              </div>
              <div className={`${styles['scanner-table__td']} fl fl--justify-c padding--t-16 padding--b-16`}>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {amount}
                </Text>
              </div>
              <div className={`${styles['scanner-table__td']} fl fl--justify-c padding--t-16 padding--b-16`}>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {fee ? `${fee} ${feeCurrency}` : '-'}
                </Text>
              </div>
              <div className={`${styles['scanner-table__td']} fl fl--justify-c padding--t-16 padding--b-16`}>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {from}
                </Text>
              </div>
              <div className={`${styles['scanner-table__td']} fl fl--justify-c padding--t-16 padding--b-16`}>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {to}
                </Text>
              </div>
            </div>
          )
        })}
      </div>
      <div className={`${styles['scanner-table__body']} ${styles['scanner-table__body--mobile']} width--100 padding--b-16`}>
        {transactions?.map((row, i) => {
          const { hash, time, transactionType, from, to, amount, hashFull, fee, feeCurrency } = row;
          return (
            <div key={`tr - ${hash}`} className={`${styles['scanner-table__tr']} ${styles['scanner-table__tr--mobile']} ${i === 0 ? styles['scanner-table__tr--first'] : ''} fl fl--dir-col fl--align-c`}>
              <div className={`${styles['scanner-table__td']} ${styles['scanner-table__td--mobile']} fl fl--justify-b fl--align-c fl--gap-16 padding--t-16 padding--b-16`}>
                <Text tag="h4" size="small" weight="400" className="font--family-copperplate">
                  {titles[0]}
                </Text>
                <Link
                  target="_blank"
                  href={`${process.env.NEXT_PUBLIC_ETHERSCAN_URL}/${hashFull}`}
                  className="color--extra-gray text--small font--300 hover--brown-red hover--underline font--family-ubuntu font--underline cursor--custom-pointer"
                >
                  {hash}
                </Link>
              </div>
              <div className={`${styles['scanner-table__td']} ${styles['scanner-table__td--mobile']} fl fl--justify-b fl--align-c fl--gap-16 padding--t-16 padding--b-16`}>
                <Text tag="h4" size="small" weight="400" className="font--family-copperplate">
                  {titles[1]}
                </Text>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {dayjs(time).format('YYYY-MM-DD HH:mm')}
                </Text>
              </div>
              <div className={`${styles['scanner-table__td']} ${styles['scanner-table__td--mobile']} fl fl--justify-b fl--align-c fl--gap-16 padding--t-8 padding--b-8`}>
                <Text tag="h4" size="small" weight="400" className="font--family-copperplate">
                  {titles[2]}
                </Text>
                <div className={`${styles['scanner-table__transaction-type']} fl fl--justify-c fl--align-c fl--gap-8 padding--t-8 padding--b-8 radius--100`}>
                  <Icon icon={`flat/transaction-type-${transactionType.toLowerCase()}`} size={16} />
                  <Text size="small" transform="capitalize" className="font--family-ubuntu">
                    {transactionTypesTranslation[transactionType.toLowerCase()]}
                  </Text>
                </div>
              </div>
              <div className={`${styles['scanner-table__td']} ${styles['scanner-table__td--mobile']} fl fl--justify-b fl--align-c fl--gap-16 padding--t-16 padding--b-16`}>
                <Text tag="h4" size="small" weight="400" className="font--family-copperplate">
                  {titles[3]}
                </Text>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {amount}
                </Text>
              </div>
              <div className={`${styles['scanner-table__td']} ${styles['scanner-table__td--mobile']} fl fl--justify-b fl--align-c fl--gap-16 padding--t-16 padding--b-16`}>
                <Text tag="h4" size="small" weight="400" className="font--family-copperplate">
                  {titles[4]}
                </Text>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {fee ? `${fee} ${feeCurrency || ''}` : '-'}
                </Text>
              </div>
              <div className={`${styles['scanner-table__td']} ${styles['scanner-table__td--mobile']} fl fl--justify-b fl--align-c fl--gap-16 padding--t-16 padding--b-16`}>
                <Text tag="h4" size="small" weight="400" className="font--family-copperplate">
                  {titles[5]}
                </Text>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {from}
                </Text>
              </div>
              <div className={`${styles['scanner-table__td']} ${styles['scanner-table__td--mobile']} fl fl--justify-b fl--align-c fl--gap-16 padding--t-16 padding--b-16`}>
                <Text tag="h4" size="small" weight="400" className="font--family-copperplate">
                  {titles[6]}
                </Text>
                <Text size="small" color="extra-gray" className="font--family-ubuntu">
                  {to}
                </Text>
              </div>
            </div>
          )
        })}
      </div>
    </FadeIn >
  );
};

export default TokenScannerTable;
