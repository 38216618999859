'use client'
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import './pagination.sass';
import Icon from '../Icon/index.client';

interface IPagination {
  pageCount?: number,
  pageRangeDisplayed?: number,
  marginPagesDisplayed?: number,
  breakLabel?: React.ReactNode,
  containerClassName?: string,
  pageClassName?: string,
  pageLinkClassName?: string,
  activeClassName?: string,
  extraAriaContext?: string,
  current?: number,
  search?: string,
  limit?: number,
  race?: string,
  heroRef?: string,
  category?: string
}

const DEFAULT_CLASSES = {
  activeClassName: 'pagination__page--selected',
  containerClassName: 'pagination',
  pageLinkClassName: 'pagination__link',
  pageClassName: 'pagination__page'
};

const Pagination = (props: IPagination): JSX.Element => {
  const {
    pageCount = 10,
    pageRangeDisplayed = 2,
    marginPagesDisplayed = 0,
    current = 1,
    activeClassName = DEFAULT_CLASSES.activeClassName,
    pageLinkClassName = DEFAULT_CLASSES.pageLinkClassName,
    containerClassName = DEFAULT_CLASSES.containerClassName,
    breakLabel = '...',
    extraAriaContext,
    search,
    limit,
    race,
    category,
    heroRef
  } = props;

  const [additionalsQueryProps, setAdditionalsQueryProps ] = useState({
    ...(search ? { search } : {}), 
    ...(limit ? { limit } : {}),
    ...(race ? { race } : {}),
    ...(category ? { category } : {}),
    ...(heroRef ? { "hero-ref": heroRef } : {})
  });
  useEffect(() => {
    setAdditionalsQueryProps({
      ...(search ? { search } : {}), 
      ...(limit ? { limit } : {}),
      ...(race ? { race } : {}),
      ...(category ? { category } : {}),
      ...(heroRef ? { "hero-ref": heroRef } : {})
    });
  }, [search, limit, race, category, heroRef]);

  const getPageElement = (index: number): JSX.Element => {
    let ariaLabel = `Page ${index + 1}${extraAriaContext ? ` ${extraAriaContext}` : ''}`;
    let pageClassName = DEFAULT_CLASSES.pageClassName;
    if (+current === index + 1) {
      ariaLabel = `Page ${index + 1} is your current page`;

      if (typeof (pageClassName) !== 'undefined') {
        pageClassName = `${pageClassName} ${activeClassName}`;
      } else {
        pageClassName = activeClassName;
      }
    }

    return (
      <li key={index} className={pageClassName}>
        <Link
          aria-label={ariaLabel}
          passHref
          scroll={false}
          href={
            {
              query: { 
                page: index + 1, 
                ...additionalsQueryProps
              }
            }
          }
          className={`${pageLinkClassName} fl fl--align-c fl--justify-c`}>
          {index + 1}
        </Link>
      </li>
    );
  };

  const pagination = () => {
    const items = [];

    if (pageCount === 1) return null;
    if (pageCount < pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        items.push(getPageElement(index));
      }
    }
    else {
      let leftSide = (pageRangeDisplayed);
      let rightSide = (pageRangeDisplayed - leftSide);

      if (+current > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - +current;
        leftSide = pageRangeDisplayed - rightSide;
      } else if (+current < pageRangeDisplayed / 2) {
        leftSide = +current;
        rightSide = pageRangeDisplayed - leftSide;
      }

      let index,
        breakView;
      const createPageView = (i: number) => getPageElement(i);
      if (+current > 3) {
        breakView = (
          <>
            <li key="key-1" className={DEFAULT_CLASSES.pageClassName}>
              <Link
                passHref
                scroll={false}
                href={
                  {
                    query: { 
                      page: 1,
                      ...additionalsQueryProps  
                    },
                  }
                }
                className={`${pageLinkClassName} fl fl--align-c fl--justify-c`}>
                {1}
              </Link>
            </li>
            <li key="key-11" className="pagination__page-break-view margin--l-4 margin--r-4">
              <span className="pagination__page-break-view-span margin--r-4"></span>
              <span className="pagination__page-break-view-span margin--r-4"></span>
              <span className="pagination__page-break-view-span"></span>
            </li>
          </>
        );
        items.push(breakView);
      }

      for (index = 0; index < pageCount; index++) {

        if (+current <= marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }
        if ((index >= +current - leftSide) && (index <= +current - rightSide)) {
          items.push(createPageView(index));
          continue;
        }
        if (breakLabel && items[items.length - 1] !== breakView) {
          if (+current === 1 && +pageCount > 3) {
            breakView = (
              <>
                <li key="key-8" className={DEFAULT_CLASSES.pageClassName}>
                  <Link
                    aria-label={'ariaLabel'}
                    passHref
                    scroll={false}
                    href={
                      {
                        query: { 
                          page: 3, 
                          ...additionalsQueryProps
                        }
                      }
                    }
                    className={`${pageLinkClassName} fl fl--align-c fl--justify-c`}>
                    {3}
                  </Link>
                </li>
                <li key="key-9" className={DEFAULT_CLASSES.pageClassName}>
                  <Link
                    aria-label={'ariaLabel'}
                    passHref
                    scroll={false}
                    href={
                      {
                        query: { 
                          page: 4, 
                          ...additionalsQueryProps
                        }
                      }
                    }
                    className={`${pageLinkClassName} fl fl--align-c fl--justify-c`}>
                    {4}
                  </Link>
                </li>
              </>
            );
            items.push(breakView);
          }
          if (+current === 2 && +pageCount > 3) {
            breakView = (
              <li key="key-9" className={DEFAULT_CLASSES.pageClassName}>
                <Link
                  aria-label={'ariaLabel'}
                  passHref
                  scroll={false}
                  href={
                    {
                      query: { 
                        page: 4, 
                        ...additionalsQueryProps
                      }
                    }
                  }
                  className={`${pageLinkClassName}`}>
                  {4}
                </Link>
              </li>
            );
            items.push(breakView);
          }
        }
      }
    }
    return items;
  };

  return (
    <>
      {
        pageCount > 1 ? (
        <ul className={`${containerClassName} ${DEFAULT_CLASSES.containerClassName}`}>
          <li key="key-10" className={`pagination__previous ${+current === 1 ? 'pagination__previous--disabled' : ''}`}>
            <Link
              passHref
              scroll={false}
              href={
                {
                  query: { 
                    page: +current - 1, 
                    ...additionalsQueryProps
                  }
                }
              }
              className={`pagination__arrow-previous ${+current === 1 ? 'pagination__arrow-previous--disabled' : ''}`}
            >
              <Icon
                icon="colorful/prev-arrow"
                size={14}
                color="white"
              />
            </Link>
          </li>
          {pagination()}
          <li key="key-11" className={`pagination__next ${+current === pageCount ? 'pagination__next--disabled' : ''}`}>
            <Link
              passHref
              scroll={false}
              href={
                {
                  query: { 
                    page: +current + 1, 
                    ...additionalsQueryProps
                  }
                }
              }
              className={`pagination__arrow-next ${+current === pageCount ? 'pagination__arrow-next--disabled' : ''}`}
            >
              <Icon
                icon="colorful/next-arrow"
                size={14}
                color="white"
              />
            </Link>
          </li>
        </ul>
        ) : null
      }
    </>
  );
};

export default Pagination;
