'use client';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer,
    CartesianGrid,
    Tooltip
} from 'recharts';

// Components
import ChartTooltip from '@/components/ScheduleTooltip/index.server';

// Types
import { ITextContent } from '@/types/page.types';

// Helpers
import { formatNumberValue } from '@/helpers/token.hl';

interface IChart {
    translations: ITextContent;
    className?: string;
    numberOfGames: number;
    numberOfGamers: number;
    ecosystemTransactions: number;
}

interface ICustomizedAxisTick {
    x: number;
    y: number;
    payload: any;
    isActive: boolean;
    rotate?: number;
    textAnchor?: string;
    translations: ITextContent;
}

interface IGraphItem {
    name: string;
    uv: number;
    pv: number;
}

function getQuarterAndYear(opts: {
    date: Date;
    translations: ITextContent;
}): string {
    const { date, translations } = opts;
    const {
        metaData: {
            json: {
                quarterPrefix
            } = {}
        } = {}
    } = translations;

    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const quarter = Math.ceil(month / 3);

    return `${quarterPrefix}${quarter} ${year}`;
}

const CustomizedAxisTick = (props: ICustomizedAxisTick) => {
    const {
        x,
        y,
        payload,
        isActive,
        rotate = 0,
        textAnchor = 'middle',
        translations
    } = props;

    const {
        metaData: {
            json: {
                billionSuffix = '',
                millionSuffix = '',
                thousandSuffix = ''
            } = {}
        } = {}
    } = translations || {};

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                fill={isActive ? '#CDCDCD' : '#A6A6A6'}
                className={`text--tiny font--family-ubuntu font--400 ${isActive ? 'link--secondary link--backlight' : ''}`}
                transform={`rotate(${rotate})`}
                x={0}
                y={0}
                textAnchor={textAnchor}
            >
                {formatNumberValue({
                    value: payload?.value,
                    translations: {
                        billionSuffix,
                        millionSuffix,
                        thousandSuffix
                    }
                })}
            </text>
        </g>
    );
};

const Chart = (props: IChart) => {
    const {
        translations,
        className = '',
        numberOfGames,
        numberOfGamers,
        ecosystemTransactions
    } = props;

    const isLowerMode = useMediaQuery({
        query: '(max-width: 767px)'
    });

    const isTabletMode = useMediaQuery({
        query: '(max-width: 1023px)'
    });

    const {
        metaData: {
            json: {
                volume = '',
                closingPrice = '',
                billionSuffix = '',
                millionSuffix = '',
                thousandSuffix = ''
            } = {}
        } = {}
    } = translations;

    const [data, setData] = useState<IGraphItem[]>([]);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        const result: IGraphItem[] = [];
        const currentDate = new Date();

        const PADA_C = 20; // Gamer's consuming amount per month
        const PADA_T_C = 0.1; // Consuming PADA per transaction
        const PADA_START_PRICE = 0.2; // Initial PADA price

        const initialGames = numberOfGames;
        const initialGamersPerGame = numberOfGamers;
        const initialTxnCount = ecosystemTransactions;
        const months = 36;
        const INITIAL_PADA_ON_MARKET = 1000000000;
        const monthlyGrowthRate = 0.05;

        // Arrays to store values
        const G = []; // Total gamers per month
        const txnCount = []; // Transaction count per gamer per month
        const T_C = []; // Total consuming per month
        const PADA_Price = []; // PADA price per month
        const PADA_ON_MARKET = []; // PADA tokens on market per month

        // Initialize for month 0
        G[0] = initialGames * initialGamersPerGame;
        txnCount[0] = initialTxnCount;
        PADA_ON_MARKET[0] = INITIAL_PADA_ON_MARKET;

        // Simulation loop
        for (let i = 0; i < months; i++) {
            if (i > 0) {
                // Gamer growth
                G[i] = G[i - 1] * (1 + monthlyGrowthRate);

                // Transaction count growth
                txnCount[i] = txnCount[i - 1] * (1 + monthlyGrowthRate);

                // Update PADA tokens on market
                PADA_ON_MARKET[i] = PADA_ON_MARKET[i - 1];

                // Add 23 million tokens every 6 months during the first 24 months (2 years)
                if ((i % 6 === 0) && i <= 24) {
                    PADA_ON_MARKET[i] += 23000000;
                }
            }

            // Total consuming per month
            T_C[i] = G[i] * PADA_C * txnCount[i] * PADA_T_C;

            // PADA price calculation
            PADA_Price[i] =
                PADA_START_PRICE *
                (1 + (T_C[i] - PADA_ON_MARKET[i]) / PADA_ON_MARKET[i]);

            // Ensure non-negative price
            if (PADA_Price[i] < 0) {
                PADA_Price[i] = 0;
            }

            currentDate.setMonth(currentDate.getMonth() + 1);

            result.push({
                name: getQuarterAndYear({
                    date: currentDate,
                    translations
                }),
                pv: Math.trunc(T_C[i]),
                uv: Math.trunc((PADA_Price[i]) * 1000) / 1000
            });
        }

        setData(result.filter((_e, i) => (i + 1) % 3 === 0));
    }, [ecosystemTransactions, numberOfGamers, numberOfGames]);

    const handleMouseMove = (value: any) => {
        setActiveIndex(value?.activeTooltipIndex);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    return (
        <div className={className}>
            <ResponsiveContainer className="rechart-custom recharts-custom-cursor-hover" width="100%" height="100%">
                <ComposedChart
                    data={data}
                    margin={{
                        top: 20,
                        right: !isLowerMode ? 44 : -14,
                        bottom: 20,
                        left: !isLowerMode ? 44 : -14,
                    }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                >
                    <defs>
                        <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="-20%" stopColor="rgba(210, 178, 177, 0.7)" />
                            <stop offset="5%" stopColor="#6C1304" />
                            <stop offset="100%" stopColor="rgba(108, 19, 4, 0.1)" />
                        </linearGradient>

                        background: linear-gradient(180deg, #D2B2B1 -22.83%, #6C1304 18.44%, rgba(108, 19, 4, 0.1) 100%);

                    </defs>
                    <XAxis
                        dataKey="name"
                        tickLine={false}
                        stroke='#E4E4E433'
                        tick={(props) => (
                            <CustomizedAxisTick
                                {...props}
                                textAnchor='end'
                                isActive={props.index === activeIndex}
                                rotate={-90}
                                translations={translations}
                            />
                        )}
                        interval={0}
                    />
                    <YAxis
                        yAxisId="left-axis"
                        tickLine={false}
                        stroke='#E4E4E433'
                        orientation='left'
                        axisLine={false}
                        tickFormatter={(value) => `${value}`}
                        tick={(props) => (
                            <CustomizedAxisTick {...props} translations={translations} isActive={false} />
                        )}
                        label={
                            !isLowerMode ? {
                                value: volume,
                                angle: -90,
                                position: 'insideLeft',
                                offset: 0,
                                dy: 40,
                                fill: '#A6A6A6',
                                className: 'font--400 text--tiny font--family-ubuntu'
                            } : undefined
                        }
                    />
                    <YAxis
                        yAxisId="right-axis"
                        tickLine={false}
                        stroke='#E4E4E433'
                        axisLine={false}
                        orientation="right"
                        tick={(props) => (
                            <CustomizedAxisTick {...props} translations={translations} isActive={false} />
                        )}
                        label={
                            !isLowerMode ? {
                                value: closingPrice,
                                angle: -90,
                                position: 'insideRight',
                                offset: 0,
                                dy: -30,
                                dx: 20,
                                fill: '#A6A6A6',
                                className: 'font--400 text--tiny font--family-ubuntu'
                            } : undefined
                        }
                    />
                    <Tooltip content={<ChartTooltip formatNumber translations={{
                        billionSuffix,
                        millionSuffix,
                        thousandSuffix,
                        uv: closingPrice,
                        pv: volume
                    }} />} cursor={false} />
                    <CartesianGrid vertical={false} stroke='#E4E4E433' />
                    <Bar dataKey="uv" barSize={!isLowerMode ? (isTabletMode ? 14 : 40) : 14} fill="url(#barGradient)" yAxisId="right-axis" />
                    <Line type="monotone" dataKey="pv" strokeWidth="2" stroke="#6C1304" yAxisId="left-axis" />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
}

export default Chart;
