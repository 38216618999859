"use client";

const Layer = (props: { children: React.ReactNode }) => {
    const { children } = props;

    return (
        <div className={`overflow--hidden width--100`}>
            {
                children
            }
        </div>
    )
}

export default Layer;