"use client";
import { useEffect, useRef, useState } from "react";
import { useScroll, useMotionValueEvent, LazyMotion, domAnimation, m } from "framer-motion";
import roadmapStyles from './index.module.sass';
import { useMediaQuery } from 'react-responsive';

const breakpoints = [
    // 767px
    {
        name: 'mobile',
        hookedYPositionNewValueModifier: 4,
        initialCircle: { cx: "105", cy: "7", r: "7", fill: "#747474" },
        path: "M105.021 0C105.021 191.5 244.021 201 311.521 291C379.021 381 -45.6088 488.212 70.5208 594.5C159.021 675.5 524.521 653.5 320.021 821.5C115.521 989.5 -112.005 1003.12 64.0208 1092C216.521 1169 539.021 1290.5 326.021 1456.5C113.021 1622.5 -180.48 1649.5 156.52 1797C493.52 1944.5 338.521 2079.5 257.52 2120.5C144.521 2177.7 -11.5796 2224.2 6.02045 2309C42.5487 2485 388.521 2445.5 388.521 2569.5C388.52 2773.5 17.187 2751.77 61.021 2835",
        pathGradientOffset: "97%",
        circles: [
            { threshold: 0.065, cx: "263", cy: "244" },
            { threshold: 0.14, cx: "118", cy: "472" },
            { threshold: 0.236, cx: "344", cy: "692" },
            { threshold: 0.322, cx: "121", cy: "948" },
            { threshold: 0.436, cx: "317", cy: "1229" },
            { threshold: 0.552, cx: "103", cy: "1591" },
            { threshold: 0.658, cx: "290", cy: "1872" },
            { threshold: 0.764, cx: "96", cy: "2197" },
            { threshold: 0.882, cx: "339", cy: "2504" },
            { threshold: 0.984, cx: "97", cy: "2786" }
        ]
    },
    // 1023px
    {
        name: 'tabletL',
        hookedYPositionNewValueModifier: 3,
        initialCircle: { cx: "303", cy: "7", r: "7", fill: "#747474" },
        path: "M301.402 1C349.352 94.4452 294.405 156.214 253.947 195.281C213.488 234.349 -28.659 320.212 4.50032 421.5C52.9519 569.5 443.5 545.5 394 693C344.5 840.5 -43.2563 921.936 170 1094C383.256 1266.06 327.5 1304.5 207 1520.5C86.5 1736.5 492 1927 359.5 2093C227 2259 -56 2369.5 87.5 2537.5C175.479 2640.5 466.9 2710.9 462.5 2850.5C457 3025 80.5 3029 80.5 3223.5C80.5 3379.1 463.5 3504.5 497.5 3653",
        pathGradientOffset: "98%",
        circles: [
            { threshold: 0.095, cx: "36", cy: "336" },
            { threshold: 0.206, cx: "393", cy: "646" },
            { threshold: 0.284, cx: "141", cy: "922" },
            { threshold: 0.372, cx: "313", cy: "1278" },
            { threshold: 0.425, cx: "205", cy: "1523" },
            { threshold: 0.523, cx: "372", cy: "1960" },
            { threshold: 0.614, cx: "124", cy: "2310" },
            { threshold: 0.753, cx: "442", cy: "2786" },
            { threshold: 0.866, cx: "94", cy: "3166" },
            { threshold: 0.97, cx: "406", cy: "3536" }
        ]
    },
    // 1439px
    {
        name: 'laptopL',
        hookedYPositionNewValueModifier: 4,
        viewBox: "-10 -10 489 2718",
        initialCircle: { cx: "172", cy: "7", r: "7", fill: "#747474" },
        path: "M 169.451 1 C 217.45 89.5 100.5 150.305 60.0002 187.305 C 19.5007 224.305 -34.4998 295.805 33.4996 367.305 C 129.394 468.136 369.5 554.805 369.5 764.305 S 30.0709 988.605 7.5001 1179.3 C -5.105 1285.8 58.038 1352.3 169.451 1467.8 C 280.865 1583.3 323.501 1797.3 197 1990.8 C 70.4988 2184.3 149 2382.3 379.5 2580.3",
        pathGradientOffset: "95%",
        circles: [
            { threshold: 0.084, cx: "43", cy: "204" },
            { threshold: 0.188, cx: "148", cy: "458" },
            { threshold: 0.222, cx: "236", cy: "524" },
            { threshold: 0.34, cx: "350", cy: "844" },
            { threshold: 0.468, cx: "37", cy: "1104" },
            { threshold: 0.606, cx: "173", cy: "1472" },
            { threshold: 0.665, cx: "262", cy: "1638" },
            { threshold: 0.76, cx: "233", cy: "1924" },
            { threshold: 0.845, cx: "137", cy: "2174" },
            { threshold: 0.93, cx: "230", cy: "2424" }
        ]
    },
    // 1679px
    {
        name: 'laptopXl',
        hookedYPositionNewValueModifier: 4,
        viewBox: "-10 -10 489 2718",
        initialCircle: { cx: "166", cy: "7", r: "7", fill: "#747474" },
        path: "M 161.999 1 C 247.499 107 162.499 205 121.999 242 C 81.4998 279 -25.1934 349.072 7.9999 445 C 53.5017 576.5 398 622 398 831.5 S 43.0708 1062.8 20.5 1253.5 C 0.9746 1418.47 200.001 1478.7 290 1572 S 403.152 1890.83 269.5 2043 C 205.5 2115.87 22.5 2341 298 2580.5",
        pathGradientOffset: "96%",
        circles: [
            { threshold: 0.102, cx: "76", cy: "282" },
            { threshold: 0.15, cx: "2", cy: "412" },
            { threshold: 0.222, cx: "160", cy: "574" },
            { threshold: 0.324, cx: "394", cy: "794" },
            { threshold: 0.499, cx: "41", cy: "1194" },
            { threshold: 0.609, cx: "175", cy: "1484" },
            { threshold: 0.7, cx: "356", cy: "1710" },
            { threshold: 0.766, cx: "341", cy: "1920" },
            { threshold: 0.889, cx: "154", cy: "2263" },
            { threshold: 0.966, cx: "222", cy: "2500" }
        ]
    },
    // default
    {
        name: 'default',
        hookedYPositionNewValueModifier: 4,
        viewBox: "0 0 489 2718",
        initialCircle: { cx: "194", cy: "7", r: "7", fill: "#747474" },
        path: "M 189.948 1 C 252.948 95.5 259.949 171.548 189.948 235.5 S -44.6518 391.5 14.4473 508 S 487.449 596 487.449 857 S 75.0194 1132.8 52.4487 1323.5 C 32.9232 1488.47 222.952 1561.7 312.95 1655 C 402.949 1748.3 444.949 1943.5 312.95 2107 C 180.952 2270.5 170.949 2443 458.449 2716.5",
        pathGradientOffset: "92%",
        circles: [
            { threshold: 0.081, cx: "169", cy: "253" },
            { threshold: 0.15, cx: "6", cy: "432" },
            { threshold: 0.287, cx: "380", cy: "678" },
            { threshold: 0.346, cx: "486", cy: "845" },
            { threshold: 0.511, cx: "93", cy: "1241" },
            { threshold: 0.582, cx: "97", cy: "1470" },
            { threshold: 0.697, cx: "376", cy: "1760" },
            { threshold: 0.764, cx: "378", cy: "1992" },
            { threshold: 0.845, cx: "237", cy: "2242" },
            { threshold: 0.924, cx: "285", cy: "2512" }
        ]
    }
];

const useCurrentBreakpoint = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const isTabletL = useMediaQuery({ query: '(max-width: 1023px)' });
    const isLaptopL = useMediaQuery({ query: '(max-width: 1439px)' });
    const isLaptopXl = useMediaQuery({ query: '(max-width: 1679px)' });

    switch (true) {
        case isMobile:
            return breakpoints.find(bp => bp.name === 'mobile')!;
        case isTabletL:
            return breakpoints.find(bp => bp.name === 'tabletL')!;
        case isLaptopL:
            return breakpoints.find(bp => bp.name === 'laptopL')!;
        case isLaptopXl:
            return breakpoints.find(bp => bp.name === 'laptopXl')!;
        default:
            return breakpoints.find(bp => bp.name === 'default');
    }
};

const RoadLine = () => {
    const { scrollYProgress } = useScroll();
    const [hookedYPosition, setHookedYPosition] = useState(0);
    const [currentElementPosition, setCurrentElementPosition] = useState(0);
    const [isMounted, setIsMounted] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const currentBreakpoint = useCurrentBreakpoint();

    useMotionValueEvent(scrollYProgress, "change", (current) => {
        const rect = ref?.current?.getBoundingClientRect();
        if (!rect) return;

        if (rect.top - window.innerHeight + 300 < 0) {
            !currentElementPosition && setCurrentElementPosition(current);
            const newValue = (current - currentElementPosition) * (currentBreakpoint?.hookedYPositionNewValueModifier || 0);
            setHookedYPosition(newValue >= 0 ? newValue : 0);
        } else {
            if (hookedYPosition) {
                setHookedYPosition(0);
            }
        }
    });

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return !currentBreakpoint ? null : (
        <div ref={ref}>
            {
                isMounted ? (
                    <LazyMotion features={domAnimation}>
                        <svg className={roadmapStyles['roadmap-line']} viewBox={currentBreakpoint?.viewBox} fill="none">
                            <defs>
                                <linearGradient id="gradientStrokeDefault" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset={currentBreakpoint?.pathGradientOffset} stopColor="#747474" />
                                    <stop offset="100%" stopColor="rgba(#747474, 0)" />
                                </linearGradient>
                                <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset={currentBreakpoint?.pathGradientOffset} stopColor="#6C1304" />
                                    <stop offset="100%" stopColor="rgba(#6C1304, 0)" />
                                </linearGradient>
                            </defs>
                            <circle
                                cx={currentBreakpoint.initialCircle.cx}
                                cy={currentBreakpoint.initialCircle.cy}
                                r={currentBreakpoint.initialCircle.r}
                                fill={currentBreakpoint.initialCircle.fill}
                            />
                            <path
                                d={currentBreakpoint.path}
                                stroke="url(#gradientStrokeDefault)"
                                strokeWidth={2}
                                pathLength={1}
                            />
                            <path
                                d={currentBreakpoint.path}
                                stroke="url(#gradientStroke)"
                                strokeWidth={2}
                                pathLength={1}
                                strokeDasharray={`${hookedYPosition}px 1px`}
                            />
                            {hookedYPosition > 0 && (
                                <circle
                                    cx={currentBreakpoint.initialCircle.cx}
                                    cy={currentBreakpoint.initialCircle.cy}
                                    r="7"
                                    fill="#6C1304"
                                />
                            )}
                            {currentBreakpoint.circles
                                .filter(circle => hookedYPosition > circle.threshold)
                                .map((circle, index) => (
                                    <m.circle
                                        key={index}
                                        transition={{ duration: 0.2 }}
                                        initial={{ scale: 0 }}
                                        animate={{ scale: 1 }}
                                        exit={{ scale: 0 }}
                                        cx={circle.cx}
                                        cy={circle.cy}
                                        r="7"
                                        fill="#6C1304"
                                    />
                                ))
                            }
                        </svg>
                    </LazyMotion>
                ) : null
            }
        </div>
    )
}

export default RoadLine;