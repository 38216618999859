'use client';
import React, { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useMediaQuery } from 'react-responsive';

// Components
import ChartTooltip from '@/components/ScheduleTooltip/index.server';

// Helpers
import { formatNumberValue } from '@/helpers/token.hl';

// Types
import { ITextContent } from '@/types/page.types';
import { IVestingChartData } from '../../types';

interface IChart {
    vestingChartData: IVestingChartData[];
    translations: ITextContent;
    className?: string;
}

interface ICustomizedAxisTick {
    x: number;
    y: number;
    payload: any;
    isActive: boolean;
    rotate?: number;
    textAnchor?: string;
    translations: ITextContent;
    abbreviation?: boolean;
}

const CustomizedAxisTick = (props: ICustomizedAxisTick) => {
    const {
        x,
        y,
        payload,
        isActive,
        rotate = 0,
        textAnchor = 'middle',
        translations: {
            metaData: {
                json: {
                    billionSuffix = '',
                    millionSuffix = '',
                    thousandSuffix = ''
                } = {}
            } = {}
        } = {},
        abbreviation = false
    } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                fill={isActive ? '#CDCDCD' : '#A6A6A6'}
                className={`text--tiny font--family-ubuntu font--400 ${isActive ? 'link--secondary link--backlight' : ''}`}
                transform={`rotate(${rotate})`}
                x={0}
                y={0}
                textAnchor={textAnchor}
            >
                {
                    abbreviation ? formatNumberValue({
                        value: payload?.value,
                        translations: {
                            billionSuffix,
                            millionSuffix,
                            thousandSuffix
                        }
                    }) : payload?.value
                }
            </text>
        </g>
    );
};

const VestingChart = (props: IChart) => {
    const {
        vestingChartData = [],
        translations,
        translations: {
            metaData: {
                json: {
                    volume = '',
                    closingPrice = '',
                    billionSuffix = '',
                    millionSuffix = '',
                    thousandSuffix = '',
                    gameTreasuryUnlock = '',
                    publicInvestors = '',
                    seed = '',
                    team = ''
                } = {}
            } = {}
        } = {},
        className = ''
    } = props;

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const isLowerMode = useMediaQuery({
        query: '(max-width: 767px)'
    });

    const handleMouseMove = (value: any) => {
        setActiveIndex(value?.activeTooltipIndex);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    return (
        <div className={className}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={vestingChartData}
                    margin={{
                        top: 20,
                        right: !isLowerMode ? 44 : 0,
                        bottom: 20,
                        left: !isLowerMode ? 44 : 0,
                    }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                >
                    <defs>
                        <linearGradient id="lightInputStroke" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="17.9%" stopColor="#ffc6c6" stopOpacity={0.4} />
                            <stop offset="42.85%" stopColor="#d87b7b" stopOpacity={0.32} />
                            <stop offset="103.93%" stopColor="#6c1304" stopOpacity={0.4} />
                        </linearGradient>
                        <linearGradient id="pinkStroke" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="17.9%" stopColor="#FFC6C6" stopOpacity={0.4} />
                            <stop offset="42.85%" stopColor="#C25151" stopOpacity={0.32} />
                            <stop offset="103.93%" stopColor="#6c1304" stopOpacity={0.4} />
                        </linearGradient>

                        <linearGradient id="colorTreasury" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="43.36%" stopColor="#360c0a" stopOpacity={0.8} />
                            <stop offset="97.02%" stopColor="#360c0a" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorInvestors" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="8.03%" stopColor="#5F1412" stopOpacity={1} />
                            <stop offset="95.16%" stopColor="#5F1412" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorTreasuryStroke" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="17.9%" stopColor="#ffc6c6" stopOpacity={0.4} />
                            <stop offset="42.85%" stopColor="#d87b7b" stopOpacity={0.32} />
                            <stop offset="103.93%" stopColor="#6c1304" stopOpacity={0.4} />
                        </linearGradient>
                        <linearGradient id="colorSeed" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#6c1304" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#6c1304" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorTeam" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="22.31%" stopColor="#933431" stopOpacity={0.7} />
                            <stop offset="101.31%" stopColor="#933431" stopOpacity={0.05} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="year"
                        tickLine={false}
                        stroke='#E4E4E433'
                        tick={(props) => (
                            <CustomizedAxisTick
                                {...props}
                                isActive={props.index === activeIndex}
                                translations={translations}
                            />
                        )}
                        tickMargin={10}
                    />
                    <YAxis
                        tickLine={false}
                        stroke='#E4E4E433'
                        orientation='left'
                        axisLine={false}
                        tickFormatter={(value) => `${value}`}
                        tick={(props) => (
                            <CustomizedAxisTick
                                {...props}
                                translations={translations}
                                isActive={false}
                                abbreviation
                            />
                        )}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip content={<ChartTooltip formatNumber translations={{
                        billionSuffix,
                        millionSuffix,
                        thousandSuffix,
                        uv: closingPrice,
                        pv: volume
                    }} />} cursor={false} />
                    <Area
                        type="monotone"
                        dataKey="treasury"
                        stroke="url(#lightInputStroke)"
                        fillOpacity={1}
                        fill="url(#colorTreasury)"
                        name={gameTreasuryUnlock}
                    />
                    <Area
                        type="monotone"
                        dataKey="investors"
                        stroke="url(#lightInputStroke)"
                        fillOpacity={1}
                        fill="url(#colorInvestors)"
                        name={publicInvestors}
                    />
                    <Area
                        type="monotone"
                        dataKey="seed"
                        stroke="url(#lightInputStroke)"
                        fillOpacity={1}
                        fill="url(#colorSeed)"
                        name={seed}
                    />
                    <Area
                        type="monotone"
                        dataKey="team"
                        stroke="url(#pinkStroke)"
                        fillOpacity={1}
                        fill="url(#colorTeam)"
                        name={team}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default VestingChart;
