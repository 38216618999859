'use client';

// Components
import Text from '@/common/Text/index.server';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

interface IProps {
  translations: ITextContent;
}

const TokenScannerPlug = (props: IProps) => {
  const {
    translations: {
      metaData: {
        json: {
          noTransactionPlug = ''
        } = {}
      } = {}
    } = {}
  } = props;

  return (
    <div className={`${styles['scanner-plug']} width--100 fl fl--align-c fl--justify-c`}>
      <Text
        size="x-default-subtitle"
        weight="400"
        fontFamily="copperplate"
        className={`${styles['scanner-plug__text']} turn--center`}
      >
        {noTransactionPlug}
      </Text>
    </div>
  );
};

export default TokenScannerPlug;
