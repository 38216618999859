'use client';
import React from 'react';

import { useOauthStore } from '@/store/zustand/oauth.store';
import Profile from '@/components/Profile/index.client';
import DynamicAccountLink from '@/components/DynamicAccountLink/index.client';

// Styles
import styles from './index.module.sass';

const AuthWrapper = (props: { translations: { [key: string]: string } }) => {
    const {
        translations,
        translations: {
            joinUs = ''
        } = {}
    } = props;
    const {
        isAuthorized,
    } = useOauthStore();

    return (
        <div className={styles['auth-wrapper']}>
            {
                isAuthorized ? (
                    <Profile translations={translations} />
                ) : (
                    <DynamicAccountLink className={`${styles['auth-wrapper__login']} link--primary font--uppercase font--family-copperplate text--small cursor--custom-pointer`}>
                        {joinUs}
                    </DynamicAccountLink>
                )
            }
        </div>
    );
};

export default AuthWrapper;
