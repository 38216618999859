'use client'
import React, { useEffect, useState } from 'react';
import { usePathname } from "next/navigation";
import Link from 'next/link';
import Image from 'next/image';

// Components
import Text from '@/common/Text/index.server';
import Icon from "@/common/Icon/index.client";
import Logo from '../Logo/index.server';
import LoaderBlur from '@/common/LoaderBlur/index.client';
import Language from '../Language/index.client';

// Constants
import { NET_TYPES } from '@/constants/net.const';

// Types
import { IMenuSingleLanguage } from '@/types/menu.types';

// Hooks
import useLogout from '@/hooks/useLogout';

// style
import styles from './index.module.sass';

interface SideBarTypes {
    menu: IMenuSingleLanguage[],
    lang: string
    translations: { [key: string]: string },
    email: string
}

const SideBar = (props: SideBarTypes) => {
    const {
        menu,
        lang,
        translations: {
            backToWebsite = '',
            restorePassword = '',
            logOut = '',
        },
        email
    } = props;
    const [openMenu, setOpenMenu] = useState(false);
    const [netType, setNetType] = useState<string | null>(null);
    const pathname = usePathname();
    const { isLoggingOut, logoutHandler } = useLogout();

    const openMenuHandleClick = () => setOpenMenu((prev) => !prev);

    useEffect(() => {
        setOpenMenu(false);
        const storageNetType = sessionStorage.getItem('account-net-type')
        setNetType(storageNetType || NET_TYPES.TEST_NET);
    }, [pathname])

    return (
        <>
            <div className="fl fl--align-st">
                <div
                    className={`${styles['side-bar__burger']} cursor--custom-pointer`}
                    onClick={() => openMenuHandleClick()}
                >
                    <Icon
                        icon="flat/menu-burger"
                        size={48}
                        color="white"
                    />
                </div>
            </div>
            {openMenu ? <div className={styles['side-bar__mobile-backdrop']} onClick={openMenuHandleClick} role="button" tabIndex={0} /> : null}
            <div className={`${styles['side-bar']} ${openMenu ? styles['side-bar--active'] : ''} scrollbar scrollbar--invisible background--stone`}>
                <LoaderBlur fetch={isLoggingOut} />
                <Logo
                    lang={lang}
                    path={`/account/${netType || NET_TYPES.TEST_NET}`}
                    classNames={{
                        wrapper: styles['side-bar__logo']
                    }}
                />
                <Language isClientArea lang={lang} className={styles['side-bar__lang']} />
                <span
                    role="button"
                    tabIndex={-1}
                    onClick={openMenuHandleClick}
                    className={`${styles['side-bar__button-close']} cursor--custom-pointer`}
                >
                    <Icon
                        size={48}
                        icon="flat/side-bar-close"
                        color="white"
                    />
                </span>
                <div className={`${styles['side-bar__profile']} fl fl--dir-col fl--align-c padding--b-32 margin--b-32 fl--gap-16`}>
                    <Image
                        src="/img/profile.svg"
                        alt="profile"
                        width={48}
                        height={48}
                    />
                    <div className={`${styles['side-bar__profile-menu']} fl fl--dir-col fl--align-c fl--gap-32`}>
                        <Text size="small" color="middle-gray" className="font--300 font--family-ubuntu">
                            {email}
                        </Text>
                        <Link href={{ query: { oauth: true, form: 'restore-password-by-old' } }} className={`${styles['side-bar__menu-item']} link--secondary text--small font--300 fl fl--align-c font--family-ubuntu cursor--custom-pointer`}>
                            <div className={`${styles['side-bar__restore-icon']} margin--r-8 margin--t-2`} />
                            {restorePassword}
                        </Link>
                        <span onClick={logoutHandler} className={`${styles['side-bar__menu-item']} link--secondary fl font--300 text--small font--family-ubuntu cursor--custom-pointer`}>
                            <Icon
                                size={16}
                                icon="flat/logout"
                                classNames={{ wrapper: 'margin--r-8' }}
                                color="white"
                            />
                            {logOut}
                        </span>
                    </div>
                </div>
                <ul className="fl fl--dir-col fl--align-c fl--gap-38">
                    {
                        menu?.map((item) => {
                            const {
                                uri,
                                label,
                                name
                            } = item;
                            return (
                                <li key={label} className="fl fl--justify-c cursor--custom-pointer">
                                    <Link href={uri} className={`${styles['side-bar__menu-item']} link--secondary fl fl--justify-c relative--core font--no-wrap text--small font--300 font--family-ubuntu cursor--custom-pointer`}>
                                        <span onClick={() => setOpenMenu(false)}>{name}</span>
                                    </Link>
                                </li>
                            )
                        })
                    }
                    <li className="fl fl--justify-c cursor--custom-pointer">
                        <Link href="/" className={`${styles['side-bar__menu-item']} link--secondary text--small font--300 font--family-ubuntu cursor--custom-pointer`}>
                            {backToWebsite}
                        </Link>
                    </li>

                </ul>
            </div>
        </>
    )
}

export default SideBar;
