'use client'

import React, { useState } from 'react';
import Slider from 'react-slick';

import { ISingleLanguageEvent } from '@/types/event.types';

import FadeIn from '@/components/Animations/FadeIn/index.client';
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';

import sliderStyles from '../../index.module.sass';
import './slick.sass';
import './slick.theme.sass';

const Carousel = ({ events, changeSlide }: { events: ISingleLanguageEvent[], changeSlide: (slideNumber: number) => void }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        rows: 1,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    rows: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    rows: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    rows: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 549,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    rows: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            }
        ]
    };
    const [activeSlide, setActiveSlide] = useState(0);

    const changeSlideHandler = (index: number) => {
        changeSlide(index)
        setActiveSlide(index)
    }

    return (
        <div className={`${sliderStyles.slider__container} width--100`}>
            <Slider {...settings} className="race-menu">
                {
                    events?.map((item: ISingleLanguageEvent, index) => {
                        const { slug, postId, contents: { title: raceTitle } } = item;
                        return (
                            <div key={`race-${slug}-${postId}`}>
                                <div onClick={() => changeSlideHandler(index)} className={`${sliderStyles.slider__item} ${sliderStyles['slider__item--carousel']} ${index === activeSlide ? `${sliderStyles['slider__item--active']}` : ''} fl fl--align-c fl--justify-c fl--dir-col cursor--custom-pointer`}>
                                    <FadeIn duration={0.5} delay={0}>
                                        <div className={`${sliderStyles['slider__item-icon-wrapper']} fl fl--align-c fl--justify-c`}>
                                            <Icon
                                                size={140}
                                                color="color--pale-gray"
                                                icon={`colorful/race-${slug}`}
                                                classNames={{
                                                    icon: sliderStyles['slider__item-icon']
                                                }}
                                            />
                                        </div>
                                    </FadeIn>
                                    <FadeIn duration={0.5} delay={0.5}>
                                        <Text
                                            size="x-default-subtitle"
                                            color="color--pale-gray"
                                            className={`${sliderStyles['slider__item-text']} font--family-copperplate font--uppercase`}
                                        >
                                            {raceTitle}
                                        </Text>
                                    </FadeIn>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default Carousel;
