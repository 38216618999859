"use client";
import { useState } from "react";
import { LazyMotion, domAnimation, m } from "framer-motion";

const RoadText = (props: { children: React.ReactNode, classNameWrapper?: string, className?: string, classNameActive?: string }) => {
    const { children, classNameWrapper, className, classNameActive } = props;
    const [isInView, setIsInView] = useState(false);

    return (
        <LazyMotion features={domAnimation}>
            <m.span
                initial={{ color: "#605F5F" }}
                whileInView={{ color: "#CDCDCD" }}
                exit={{ color: "#605F5F" }}
                viewport={{ margin: "-100px" }}
                transition={{ duration: 2 }}
                onViewportEnter={() => setIsInView(true)}
                onViewportLeave={() => setIsInView(false)}
                className={classNameWrapper}
            >
                <span className={`${className} ${isInView ? `${classNameActive || ''}` : ""}`} />
                <span className="font--family-copperplate relative--core z--2">
                    {children}
                </span>
            </m.span>
        </LazyMotion>
    )
}

export default RoadText;
