"use client"
import { useEffect, useState } from "react";
import { LazyMotion, domAnimation, m } from "framer-motion";
import animationStyles from "./index.module.sass";

import imagePlayers from '../players.svg'
import imageCommunity from '../community.svg'
import imageUniverse from '../universe.svg'
import imageHolders from '../holders.svg'

import Rectangles from "./Rectangles/index.client";
import HorizontalLine from "./HorizontalLine/index.client";
import Tags from "./Tags/index.client";
import CurvedLine from "./CurvedLine/index.client";
import VerticalLine from "./VerticalLine/index.client";
import { useMediaQuery } from 'react-responsive'
import { ITextContent } from "@/types/text.types";

const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i: number) => {
        const delay = 1 + i * 0.5;
        return {
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: { delay, type: "spring", duration: 3, bounce: 0 },
                opacity: { delay, duration: 1 }
            }
        };
    }
};

const move = {
    hidden: { opacity: 0 },
    visible: ({ delay, cords }: any) => {
        const delays = 1 + delay * 0.5;
        return {
            opacity: 1,
            ...cords,
            transition: {
                delay: delays,
                duration: 4,
                repeat: Infinity,
                repeatType: "loop",
                ease: "linear",
                opacity: { duration: 1 }
            },
        };
    }
};

const Animation = (props: { text: ITextContent }) => {
    const {
        text: {
            metaData: {
                json: {
                    entities: {
                        holders: {
                            name: holdersName = '',
                            flow: {
                                forward: holdersFlowForward = '',
                                backward: holdersFlowBackward = ''
                            } = {}
                        } = {},
                        community: {
                            name: communityName = '',
                            flow: {
                                forward: communityFlowForward = '',
                                backward: communityFlowBackward = ''
                            } = {}
                        } = {},
                        universe: {
                            name: universeName = ''
                        } = {},
                        players: {
                            name: playersName = '',
                            flow: {
                                forward: playersFlowForward = '',
                                backward: playersFlowBackward = ''
                            } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = {}
    } = props;
    const [isMounted, setIsMounted] = useState(false);
    const isLowerMode = useMediaQuery({
        query: '(max-width: 767px)'
    })

    useEffect(() => {
        setIsMounted(true);
    }, [])

    return (
        <LazyMotion features={domAnimation}>
            <div className={animationStyles['economy-rectangles']}>
                <div className={`${animationStyles['economy-rectangles__wrapper']} ${animationStyles['economy-rectangles__wrapper--holders']} fl fl--align-c fl--justify-c fl--dir-col `}>
                    <Rectangles idKey="holders" gradientPosition={{ x1: '212.755', x2: '2.39227', y1: '204.485', y2: '317.508' }} variants={draw} title={holdersName} image={imageHolders}></Rectangles>
                    {
                        isMounted ? (
                            <>
                                {
                                    isLowerMode ? (
                                        <>
                                            <VerticalLine
                                                label="holder-to-community"
                                                color={animationStyles.redColor}
                                                variants={draw}
                                                move={move}
                                                lineCodrinates={{
                                                    x1: "4",
                                                    y1: "500",
                                                    x2: "4",
                                                    y2: "0"
                                                }}
                                                moveCustomRules={{
                                                    delay: 5.5,
                                                    cords: {
                                                        x: [-6, -6],
                                                        y: [0, 488]
                                                    }
                                                }}
                                            />
                                            <VerticalLine
                                                label="community-to-holder"
                                                color={animationStyles.greyColor}
                                                variants={draw}
                                                move={move}
                                                lineCodrinates={{
                                                    x1: "4",
                                                    y1: "0",
                                                    x2: "4",
                                                    y2: "500"
                                                }}
                                                moveCustomRules={{
                                                    delay: 5.5,
                                                    cords: {
                                                        x: [-6, -6],
                                                        y: [488, 0]
                                                    }
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <CurvedLine
                                                label="holder-to-community"
                                                color={animationStyles.redColor}
                                                variants={draw}
                                                move={move}
                                                linePath="M10 0 L11 300 118 300"
                                                moveCustomRules={{
                                                    delay: 5,
                                                    cords: {
                                                        x: [0, 0, 104],
                                                        y: [0, 296, 296],
                                                    }
                                                }}
                                                svgCustom={{
                                                    width: "309",
                                                    height: "308",
                                                    viewBox: "0 0 309 308"
                                                }}
                                            />
                                            <CurvedLine
                                                label="community-to-holder"
                                                color={animationStyles.greyColor}
                                                variants={draw}
                                                move={move}
                                                linePath="M166 350 L11 350 10 0"
                                                moveCustomRules={{
                                                    delay: 5,
                                                    cords: {
                                                        x: [150, 0, 0],
                                                        y: [346, 346, 0],
                                                    }
                                                }}
                                                svgCustom={{
                                                    width: "350",
                                                    height: "350",
                                                    viewBox: "0 0 350 350"
                                                }}
                                            />
                                        </>
                                    )
                                }
                            </>
                        ) : null
                    }
                    <Tags
                        variants={draw}
                        title={holdersFlowBackward}
                        label="holder-to-community"
                        color={animationStyles.greyColor}
                    ></Tags>
                    <Tags
                        variants={draw}
                        title={holdersFlowForward}
                        label="community-to-holder"
                        color={animationStyles.redColor}
                    ></Tags>
                </div>
                <div className={`${animationStyles['economy-rectangles__wrapper']} ${animationStyles['economy-rectangles__wrapper--players']} fl fl--align-c fl--justify-c fl--dir-col `}>
                    <Rectangles idKey="players" gradientPosition={{ x1: '212.755', x2: '2.39227', y1: '204.485', y2: '317.508' }} variants={draw} title={playersName} image={imagePlayers}></Rectangles>
                    {
                        isMounted ? (
                            <>
                                {
                                    isLowerMode ? (
                                        <>
                                            <VerticalLine
                                                label="player-to-community"
                                                color={animationStyles.redColor}
                                                variants={draw}
                                                move={move}
                                                lineCodrinates={{
                                                    x1: "4",
                                                    y1: "0",
                                                    x2: "4",
                                                    y2: "500"
                                                }}
                                                moveCustomRules={{
                                                    delay: 5.5,
                                                    cords: {
                                                        x: [-6, -6],
                                                        y: [0, 488]
                                                    }
                                                }}
                                            />
                                            <VerticalLine
                                                label="community-to-player"
                                                color={animationStyles.greyColor}
                                                variants={draw}
                                                move={move}
                                                lineCodrinates={{
                                                    x1: "4",
                                                    y1: "500",
                                                    x2: "4",
                                                    y2: "0"
                                                }}
                                                moveCustomRules={{
                                                    delay: 5.5,
                                                    cords: {
                                                        x: [-6, -6],
                                                        y: [488, 0]
                                                    }
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <CurvedLine
                                                label="player-to-community"
                                                color={animationStyles.redColor}
                                                variants={draw}
                                                move={move}
                                                linePath="M10 0 L11 300 118 300"
                                                moveCustomRules={{
                                                    delay: 5,
                                                    cords: {
                                                        x: [0, 0, 104],
                                                        y: [0, 296, 296],
                                                    }
                                                }}
                                                svgCustom={{
                                                    width: "309",
                                                    height: "309",
                                                    viewBox: "0 0 309 309"
                                                }}
                                            />
                                            <CurvedLine
                                                label="community-to-player"
                                                color={animationStyles.greyColor}
                                                variants={draw}
                                                move={move}
                                                linePath="M166 350 L11 350 10 0"
                                                moveCustomRules={{
                                                    delay: 5.5,
                                                    cords: {
                                                        x: [150, 0, 0],
                                                        y: [346, 346, 0],
                                                    }
                                                }}
                                                svgCustom={{
                                                    width: "350",
                                                    height: "350",
                                                    viewBox: "0 0 350 350"
                                                }}
                                            />
                                        </>
                                    )
                                }
                            </>
                        ) : null
                    }
                    <Tags
                        variants={draw}
                        label="player-to-universe"
                        title={playersFlowBackward}
                        color={animationStyles.greyColor}
                    ></Tags>
                    <Tags
                        variants={draw}
                        title={playersFlowForward}
                        label="universe-to-player"
                        color={animationStyles.redColor}
                    ></Tags>
                    <m.svg
                        fill="none"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, margin: "200px" }}
                        className={animationStyles['economy-rectangles__line-player-to-holder']}
                    >
                        <m.path
                            d="M500 1,L0 1Z"
                            stroke={animationStyles.greyColor}
                            variants={draw}
                            custom={5.5}
                        />
                    </m.svg>
                </div>
                <div className={`${animationStyles['economy-rectangles__wrapper']} ${animationStyles['economy-rectangles__wrapper--community']} fl fl--align-c fl--justify-c fl--dir-col `}>
                    <Rectangles idKey="community" gradientPosition={{ x1: '0', x2: '180', y1: '80', y2: '50' }} variants={draw} title={communityName} image={imageCommunity}></Rectangles>
                    <HorizontalLine
                        label="community-to-universe"
                        color={animationStyles.redColor}
                        variants={draw}
                        move={move}
                        lineCodrinates={{
                            x1: "0",
                            y1: "4",
                            x2: "500",
                            y2: "4"
                        }}
                        moveCustomRules={{
                            delay: 5.5,
                            cords: {
                                x: [0, 448],
                                y: [0, 0]
                            }
                        }}
                    ></HorizontalLine>
                    <Tags
                        variants={draw}
                        title={communityFlowForward}
                        label="community"
                        color={animationStyles.redColor}
                    ></Tags>
                </div>
                <div className={`${animationStyles['economy-rectangles__wrapper']} ${animationStyles['economy-rectangles__wrapper--universe']} fl fl--align-c fl--justify-c fl--dir-col `}>
                    <Rectangles idKey="universe" gradientPosition={{ x1: '2', x2: '10', y1: '300', y2: '190' }} variants={draw} title={universeName} image={imageUniverse}></Rectangles>
                    <HorizontalLine
                        label={'universe-to-community'}
                        color={animationStyles.greyColor}
                        variants={draw}
                        move={move}
                        lineCodrinates={{
                            x1: "500",
                            y1: "4",
                            x2: "0",
                            y2: "4"
                        }}
                        moveCustomRules={{
                            delay: 5,
                            cords: {
                                x: [464, 0],
                                y: [0, 0]
                            }
                        }}
                    ></HorizontalLine>
                    <Tags
                        variants={draw}
                        title={communityFlowBackward}
                        label="universe"
                        color={animationStyles.greyColor}
                    ></Tags>
                </div>
            </div>
        </LazyMotion>
    );
}

export default Animation;
