'use client';
import Image from 'next/image';
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';
import FadeIn from '@/components/Animations/FadeIn/index.client';
import { ISingleLanguageEvent } from "@/types/event.types";
import sliderStyles from './index.module.sass';
import { useState, useRef } from 'react';
import Blur from '@/components/Animations/Blur/index.client';
import { animate } from 'framer-motion';
import Carousel from './components/Carousel/index.client';
import { resolveImageSrc } from '@/helpers/cms/images.hl';

const Slider = (props: { event: ISingleLanguageEvent[], translation: {[key: string]: string} }) => {
    const { event, translation: {
        comingSoon: comingSoonTranslation
    } } = props;
    const [activeSlide, setActiveSlide] = useState(0);
    const refs = useRef([] as any);

    const handleRefs = (el: any, hash: string) => {
        refs.current[hash] = el;
    }

    const { 
        slug, 
        postId, 
        preview = '',
        mainExternalImage = '',
        contents: { 
            title: raceTitle = '', 
            text = ''
        } = {},
        options: { 
            json: { 
                comingSoon = false
            } = {} 
        } = {}
    } = event[activeSlide] || {};
    const heroesImage = resolveImageSrc({ internal: preview, external: mainExternalImage });
    
    const changeSlide = async (slideNumber: number) => {
        const currentImage = refs.current[`image-${activeSlide}`];
        const currentTitle = refs.current[`title-${activeSlide}`];
        const currentBack = refs.current[`back-${activeSlide}`];
        const currentDescription = refs.current[`description-${activeSlide}`];
        const currentSkills = refs.current[`skills-${activeSlide}`];

        const fadeOut = {
            y: 10, 
            opacity: 0
        }
        const blurOut = {
            filter: 'blur(50px)',
            opacity: 0
        }

        const sequence = [
            [currentImage, blurOut, {
                ease: "easeInOut",
                duration: .1
            }],
            [currentTitle, { ...fadeOut },  { at: "-0.2" }],
            [currentDescription, { ...fadeOut }, { at: "0.2" } ],
            [currentSkills, { ...fadeOut }, { at: "0.5" }],
            [currentBack, blurOut, {
                duration: .1,
                at: "-0.8"
              }],
        ] as any;

        // Should aminate each element by steps...
        // @ts-ignore
        await animate(sequence);
        setActiveSlide(slideNumber);
    }

    return (
            <div className={`${sliderStyles.slider__wrapper} fl fl--justify-b fl--align-st`}>
                    <div className={`${sliderStyles.slider__options} fl fl--justify-b fl--align-st fl--wrap`}>
                        <Carousel events={event} changeSlide={changeSlide} />
                    </div>
                    <div className={sliderStyles.slider__heroes}>
                        <div key={`hero-${slug}-${postId}`} className={`${sliderStyles.slider__slide} fl fl--align-c fl--justify-c`}>
                            <div className={sliderStyles['slider__hero-wrap']}>  
                                <Blur
                                    duration={0.1}
                                    reference={{
                                        onRef: (el) => handleRefs(el, `image-${activeSlide}`),
                                    }}
                                >
                                    <Image 
                                        unoptimized
                                        width={900}
                                        height={1100}
                                        className={`${sliderStyles.slider__hero} ${comingSoon ? sliderStyles['slider__hero--blurred'] : ''} ${sliderStyles[`slider__hero--${slug}`]}`} 
                                        alt={raceTitle}
                                        src={heroesImage}
                                    />
                                    {comingSoon ? (
                                        <Text size='big' className={sliderStyles['slider__hero-comming-soon']}>
                                            {comingSoonTranslation}
                                        </Text>
                                    ) : null}
                                </Blur>
                                <Blur 
                                    reference={{
                                        onRef: (el) => handleRefs(el, `back-${activeSlide}`),
                                    }}
                                    delay={0.2}
                                    duration={0.1} 
                                    className={sliderStyles['slider__background-symbol']}
                                >
                                    <Icon classNames={{ icon: sliderStyles['slider__background-symbol-icon']} } icon={`colorful/${slug}-symbol`}></Icon>
                                </Blur>
                            </div>
                            <div className={`${sliderStyles.slider__description}`}>
                                    <FadeIn 
                                        reference={{
                                            onRef: (el) => handleRefs(el, `title-${activeSlide}`),
                                        }}
                                        duration={0.3}
                                    >
                                        <Text size="big" className={`${sliderStyles['slider__description-title']} font--family-copperplate font--uppercase`}>{raceTitle}</Text>
                                    </FadeIn>
                                    <FadeIn 
                                        reference={{
                                            onRef: (el) => handleRefs(el, `description-${activeSlide}`),
                                        }}
                                        duration={0.3} 
                                        delay={0.3}
                                    >
                                        <div className="static" dangerouslySetInnerHTML={{__html: text}} />
                                    </FadeIn>
                                </div>
                            </div>
                    </div>
                </div>
    )
}

export default Slider;