'use client';
import React, { useEffect, useState } from 'react';

// Components
import Text from '@/common/Text/index.server';

// Helpers
import bigNumber from '@/helpers/bigNumber.hl';

// Styles
import styles from './index.module.sass';

interface ISingleControl {
    title: string;
    value: number;
    changeValue: (newValue: number) => void;
    min?: number;
    max?: number;
    step?: number;
}

const SingleControl = (props: ISingleControl) => {
    const {
        title,
        value,
        changeValue,
        min = 0,
        max = 100,
        step = 1
    } = props;

    const [gradientPercentage, setGradientPercentage] = useState(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        changeValue(newValue);
    };

    useEffect(() => {
        const percentage = ((value - min) / (max - min)) * 100;
        setGradientPercentage(percentage);
    }, [value, min, max]);

    return (
        <div className={styles.slider}>
            <div className="margin--b-16 fl fl--justify-b fl--align-c">
                <Text
                    className={styles.slider__title}
                    tag='h3'
                    size='small'
                    weight='400'
                    color='light'
                    fontFamily='copperplate'
                    transform='uppercase'
                >
                    {title}
                </Text>
                <Text
                    tag='h3'
                    size='small'
                    weight='400'
                    color='light'
                    fontFamily='copperplate'
                >
                    {bigNumber(value)}
                </Text>
            </div>
            <div className={styles['slider__input-wrapper']}>
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={handleChange}
                    className={styles.slider__input}
                    style={{
                        background: `linear-gradient(to right, #6C1304 ${gradientPercentage}%, #3C3C3C ${gradientPercentage}%)`,
                    }}
                />
            </div>
        </div>
    );
};

export default SingleControl;
